import { useSelector } from 'react-redux';
import { selectTableIdByShareCode } from '../store/slices/streaming';
import { RootState } from '../store/store';

export const useTableId = (): number | undefined => {
  const idOrShareCode = window.location.pathname.split('/')[2];
  const isNumber = !isNaN(+idOrShareCode);
  if (isNumber) {
    return +idOrShareCode;
  }
  return useSelector((state: RootState) => selectTableIdByShareCode(state, idOrShareCode));
};
