import React, { createContext, useContext, ReactNode, useState, useEffect, useMemo } from 'react';
import Cookies from 'js-cookie';
import { useForceLogoutMutation, useRefreshTokenMutation } from '../store/api/auth';
import { persistor } from '../store/store';
import { logDebug, logError, logWarn } from '../utils/ConsoleUtil';
import { useGetBalanceQuery, useLazyGetBalanceQuery, useLazyHistoryListQuery, useLazyTransactionListQuery } from '../store/api/assets';
import { useGetUserProfileQuery, useLazyGetUserProfileQuery } from '../store/api/user';
import { useSelector } from 'react-redux';
import { selectIsUnauthorized } from '../store/slices/authSlice';

interface AuthContextValue {
  isAuthorized: boolean;
  channel: BroadcastChannel;
  expiresAt?: string;
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined);
export const QUARTER_MINUTES = 1000 * 60 * 15;
export const TEN_SECONDS = 1000 * 10;
export const ACCESS_TOKEN_EXPIRE_KEY = 'access-token-expires-at';

export const AuthProvider: React.FC<{ children: ReactNode; interval?: number }> = ({ children, interval = 500 }) => {
  const getExpiresAt = () => Cookies.get(ACCESS_TOKEN_EXPIRE_KEY);
  const [refreshToken] = useRefreshTokenMutation();
  const [forceLogout] = useForceLogoutMutation();
  const [lastRefreshTime, setLastRequestTime] = useState<number>(0);
  const [tokenExpiredAt, setTokenExpiredAt] = useState<string | undefined>(getExpiresAt());
  const isApiUnauthorizedError = useSelector(selectIsUnauthorized);
  const [isAuthorized, setIsAuthorized] = useState(tokenExpiredAt ? new Date(tokenExpiredAt).getTime() > Date.now() : false);
  const channel = new BroadcastChannel('base-channel');

  const {} = useGetUserProfileQuery(undefined, { skip: !isAuthorized });
  const {} = useGetBalanceQuery(undefined, { skip: !isAuthorized });
  const [fetchUserProfile] = useLazyGetUserProfileQuery();
  const [fetchMyBalance] = useLazyGetBalanceQuery();
  const [fetchHistoryList] = useLazyHistoryListQuery();
  const [fetchTransactionList] = useLazyTransactionListQuery();

  const fetchUser = () => {
    fetchUserProfile()
      .unwrap()
      .then(() => {})
      .catch(() => {});
    fetchMyBalance()
      .unwrap()
      .then(() => {})
      .catch(() => {});
  };

  const setRefresh = () => {
    const now = Date.now();
    if (isNaN(lastRefreshTime) || now - lastRefreshTime > TEN_SECONDS) {
      setLastRequestTime(now);
      logDebug('RefreshToken will be requested.');
      refreshToken()
        .unwrap()
        .then(response => {
          logDebug('RefreshToken is successfully called : ', response?.data);
          fetchUser();
        })
        .catch(async error => {
          logError('RefreshToken is failed.', error);
          await forceLogout();
          await persistor.purge();
          if (window.location.pathname.includes('settings')) {
            window.location.href = '/'; // 초기 페이지로 리디렉션
          }
        });
    } else {
      logDebug(`RefreshToken is already called.`);
    }
  };

  useEffect(() => {
    const checkValidity = () => {
      const newExpiresAt = getExpiresAt();
      if (newExpiresAt !== tokenExpiredAt) {
        setTokenExpiredAt(newExpiresAt);
      }
      setIsAuthorized(tokenExpiredAt ? new Date(tokenExpiredAt).getTime() > Date.now() : false);
    };
    checkValidity();
    const intervalId = setInterval(checkValidity, interval);
    return () => clearInterval(intervalId);
  }, [interval, tokenExpiredAt]);

  useEffect(() => {
    if (!isAuthorized) {
      logWarn('Unauthorized : token invalidated');
      setRefresh();
    } else if (isApiUnauthorizedError) {
      logWarn('Unauthorized : 401 response received');
      setRefresh();
    } else {
      fetchUser();
    }
  }, [isAuthorized, isApiUnauthorizedError]);

  useEffect(() => {
    const newChannel = new BroadcastChannel('base-channel');
    newChannel.onmessage = event => {
      logDebug('Broadcast Channel Message', event);
      if (event.data.type === 'UPDATE_BALANCE') {
        fetchUser();
      } else if (event.data.type === 'UPDATE_ASSET') {
        fetchTransactionList({ type: 'ALL', limit: 20 })
          .unwrap()
          .then()
          .catch(() => {});
        fetchHistoryList({ limit: 20 })
          .unwrap()
          .then()
          .catch(() => {});
        fetchUser();
      }
    };
    return () => {
      newChannel.close();
    };
  }, []);

  useEffect(() => {
    const checkTokenExpiration = async () => {
      try {
        const expiresAt = Cookies.get(ACCESS_TOKEN_EXPIRE_KEY);
        if (!expiresAt) {
          logWarn('ACCESS_TOKEN_EXPIRE_KEY is not found in the cookie.');
          return;
        }
        const expiresIn = new Date(expiresAt).getTime() - Date.now();
        if (expiresIn <= QUARTER_MINUTES) {
          setRefresh();
        }
      } catch (error) {
        logError('Failed to checkTokenExpiration');
      }
    };
    checkTokenExpiration().then();

    const interval = setInterval(() => {
      checkTokenExpiration().then();
    }, TEN_SECONDS);

    return () => clearInterval(interval);
  }, []);

  const contextValue = useMemo(
    () => ({
      isAuthorized: isAuthorized && !isApiUnauthorizedError,
      expiresAt: tokenExpiredAt,
      channel: channel
    }),
    [isAuthorized, isApiUnauthorizedError, tokenExpiredAt, channel]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuthContext = (): AuthContextValue => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
};
