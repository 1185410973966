import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { ReactComponent as GoogleIcon } from 'src/assets/img/auth_google.svg';
import { ReactComponent as TwitterIcon } from 'src/assets/img/auth_x.svg';
import { XCloseIcon } from '../../common/icon/icons/XCloseIcon';
import LinearLayout from '../../layout/LinearLayout';
import { LeftText } from '../../common/text/styles';
import HorizontalLayout from '../../layout/HorizontalLayout';
import EmailInput from '../../common/input/EmailInput';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentEmail, selectIsEmailValid, setPolicyTab, setRedirectUrl } from '../../../store/slices/authSlice';
import { dismissModal, ModalType, showModal } from '../../../store/slices/modalSlice';
import Text from '../../common/text/Text';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { apiUrl } from '../../../utils/Util';
import { HorizontalLine } from '../../common/line/HorizontalLine';
import BaseButton from '../../common/button/BaseButton';
import useDevice from '../../../hooks/useDevice';

const AuthTemplate: React.FC = () => {
  const dispatch = useDispatch();
  const { isMobile } = useDevice();
  const { user } = useUserProfile();
  const email = useSelector(selectCurrentEmail);
  const isEmailValid = useSelector(selectIsEmailValid);
  const [isRequestEnable, setRequestEnable] = useState<boolean>(false);
  const [connecting, setConnecting] = useState<'None' | 'Google' | 'Twitter'>('None');

  useEffect(() => {
    setRequestEnable(email != null && email.length > 3 && isEmailValid);
  }, [email, isEmailValid]);

  const showVerificationModal = () => dispatch(showModal(ModalType.CodeVerificationModal));
  const showWalletConnectModal = () => dispatch(showModal(ModalType.ConnectingWalletModal));

  const startGoogle = () => {
    window.location.href = apiUrl + `auth/google`;
    setConnecting('Google');
    dispatch(setRedirectUrl(window.location.pathname));
  };

  const startTwitter = () => {
    window.location.href = apiUrl + 'auth/twitter';
    setConnecting('Twitter');
    dispatch(setRedirectUrl(window.location.pathname));
  };

  const openPolicy = () => {
    window.open(window.origin + '/policy', '_blank');
    dispatch(setPolicyTab('Terms of Service'));
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (isRequestEnable) {
        showVerificationModal();
      }
    }
  };

  useEffect(() => {
    if (user !== undefined) {
      dispatch(dismissModal());
    }
  }, [user, connecting]);

  if (connecting === 'None') {
    return (
      <Background>
        <HorizontalLayout margin={'16px 0 0 0'}>
          <Title isMobile={isMobile}>Sign in or Register</Title>
          <XCloseIcon onClick={() => dispatch(dismissModal())} />
        </HorizontalLayout>
        <LinearLayout gap={2}>
          <LeftText fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} text={'Email address'} />
          <EmailInput type={'email'} placeholder="Enter your email" onKeyDown={handleKeyPress} />
        </LinearLayout>
        <BaseButton
          text={'Continue'}
          size={isMobile ? 'btn40' : 'btn48'}
          textStyle={'TextMd_Semibold'}
          color={'primary'}
          disabled={!isRequestEnable}
          onClick={() => {
            if (isRequestEnable) {
              showVerificationModal();
            }
          }}
        />
        <OAuthButtons>
          <BaseButton onClick={startGoogle} size={isMobile ? 'btn40' : 'btn48'} color={'gray'}>
            <GoogleIcon />
          </BaseButton>
          <BaseButton onClick={startTwitter} size={isMobile ? 'btn40' : 'btn48'} color={'gray'}>
            <TwitterIcon />
          </BaseButton>
        </OAuthButtons>
        <HorizontalLayout margin={'0'}>
          <HorizontalLine />
          <Separator isMobile={isMobile}>OR</Separator>
          <HorizontalLine />
        </HorizontalLayout>
        <BaseButton textStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} onClick={showWalletConnectModal} color={'gray'} size={isMobile ? 'btn40' : 'btn48'} gap={8} iconSize={20} leftIcon={'MetamaskIcon'} text={'Connect with MetaMask'} />
        <LinkText isMobile={isMobile}>
          By continuing, I attest that I am at least 18 years old and I have read the{' '}
          <Link isMobile={isMobile} onClick={openPolicy}>
            Terms of Service
          </Link>
          .
        </LinkText>
      </Background>
    );
  } else {
    return (
      <Background>
        <LinearLayout>
          {connecting === 'Google' && <GoogleIcon width={72} height={72} style={{ alignSelf: 'center' }} />}
          {connecting === 'Twitter' && <TwitterIcon width={72} height={72} style={{ alignSelf: 'center' }} />}
          <Text textColor={'white'} fontStyle={'TextMd_Medium'} text={`Connecting with ${connecting}`} marginTop={16}></Text>
        </LinearLayout>
      </Background>
    );
  }
};

export default AuthTemplate;

const Background = styled.div`
  min-width: 500px;
  min-height: 228px;
  width: 600px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  align-items: start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 32px 40px;
  border-radius: 12px;
  gap: 16px;
  @media (max-width: 768px) {
    min-width: auto;
    width: 100%;
    padding: 12px 20px 32px 20px;
  }
`;

const Title = styled.div<{ isMobile: boolean }>`
  ${({ isMobile, theme }) => (isMobile ? theme.textStyles.TextMd_Semibold : theme.textStyles.TextXl_Semibold)};
  color: ${({ theme }) => theme.colors.white};
  text-align: start;
  display: flex;
`;

const OAuthButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 16px;
`;

const Separator = styled.div<{ isMobile: boolean }>`
  text-align: center;
  color: ${({ theme }) => theme.colors.gray500};
  padding: 0 16px;
  ${({ isMobile, theme }) => (isMobile ? theme.textStyles.TextSmall_Medium : theme.textStyles.TextMd_Medium)};
`;

const Link = styled.span<{ isMobile: boolean }>`
  ${({ isMobile, theme }) => (isMobile ? theme.textStyles.TextSmall_Medium : theme.textStyles.TextMd_Medium)};
  color: ${({ theme }) => theme.colors.primary500};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const LinkText = styled.span<{ isMobile: boolean }>`
  ${({ isMobile, theme }) => (isMobile ? theme.textStyles.TextSmall_Medium : theme.textStyles.TextMd_Medium)};
  color: ${({ theme }) => theme.colors.gray500};
  text-align: ${({ isMobile }) => (isMobile ? 'center' : 'start')};
`;
