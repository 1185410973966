import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Colors } from 'src/styles/Colors';

export const ShowCardWrap = styled.div`
  height: 100%;
  aspect-ratio: 1/1.405;
  box-sizing: border-box;
  display: flex !important;
  flex-shrink: 0;
`;
styled(motion.div)`
  position: relative;
  transform-style: preserve-3d;
  will-change: transform;
  width: fit-content;
`;
export const CardInner = styled.div<{ disabled?: boolean; isBestCard?: boolean; isFliped?: boolean }>`
  height: 6.5em;
  aspect-ratio: 1/1.405;
  z-index: 100;
  display: flex;
  border-radius: 0.5em;
  flex-direction: column;
  backface-visibility: hidden;
  background-color: ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.shadow.card};
  will-change: filter;
  box-sizing: border-box;
  @media (max-width: 768px) {
    border-radius: 4px;
  }
`;

export const LogoWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 1.4375em;
    height: 0.9375em;
  }
`;

export const CardFront = styled(CardInner)<{ bgColor: keyof typeof Colors; borderColor?: keyof typeof Colors }>`
  position: absolute;
  background: ${({ theme, bgColor }) => theme.colors[bgColor]};
  justify-content: space-between;
  ${({ isFliped }) => !isFliped && 'transform: rotateY(-180deg);'}
  ${({ theme, borderColor }) => borderColor && `border: 1px solid ${theme.colors[borderColor]};`}
`;

export const ShowCardFront = styled(CardFront)`
  position: initial;
  transform: none;
  height: 100%;
  aspect-ratio: 1/1.405;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 4px;
  @media (max-width: 768px) {
    gap: 0;
  }
`;

export const CardBack = styled(CardInner)`
  background-color: ${({ theme }) => theme.colors.white};
  ${({ isFliped }) => isFliped && 'transform: rotateY(-180deg);'}
  position: relative;
  box-sizing: border-box;
`;

export const CardPattern = styled.div<{ patternColor: keyof typeof Colors }>`
  padding: 0.4em;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    padding: 3px;
  }

  div {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
    object-fit: cover;
    background-color: ${({ theme, patternColor }) => theme.colors.gray700};
    will-change: auto;
  }
`;
export const CardNum = styled.div<{ color: keyof typeof Colors }>`
  padding: 6px 0 0 8px;
  ${({ theme }) => theme.textStyles.DisplayMd_Bold}
  font-size: 2em;
  line-height: 0.8em;
  @media (max-width: 768px) {
    padding: 1px 0 0 3px;
    font-size: 20px;
    line-height: 20px;
  }
  vertical-align: middle;
  color: ${({ theme, color }) => theme.colors[color]};
`;

export const ShowCardNum = styled(CardNum)`
  padding: 0;
  font-size: 22px;
  @media (max-width: 768px) {
    padding: 0;
    font-size: 16px;
    line-height: 16px;
  }
`;

export const SmallSuit = styled.div`
  display: flex;
  padding: 0 0 0 8px;

  svg {
    width: 1.25em;
    height: 1.25em;
  }

  @media (max-width: 768px) {
    padding: 1px 0 0 3px;
  }
`;
export const ShowSuit = styled(SmallSuit)`
  padding: 0;

  svg {
    width: 18px;
    height: 18px;
  }

  @media (max-width: 768px) {
    padding: 0;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;
export const BigSuit = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 4px 3px 0;

  svg {
    width: 2.5em;
    height: 2.5em;
  }
`;

export const BigNumber = styled.div`
  display: flex;
  justify-content: center;
  max-height: 1em;
  height: fit-content;
  padding-bottom: 0.12em;
  padding-right: 0.12em;
  box-sizing: border-box;
  font-size: 2.5em;
  color: ${({ theme }) => theme.colors.white};
  text-align: right;
  font-family: Yellix-Bold, Lunasima-Bold;
  font-style: normal;
  font-weight: 700;
  line-height: 0.85em;
`;
