import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card } from 'src/store/slices/streamingTypes';
import { RootState } from 'src/store/store';
import useResizeObserver from 'use-resize-observer';
import SoundManager from 'src/utils/SoundUtil';
import _ from 'lodash';
import { selectTableRotateNumber, setTableRotateNumber } from '../../../../store/slices/tableSlice';
import { DELAY_WINNING_CHIP_INVISIBLE } from '../../../../utils/AnimationUtil';
import { useTableContext } from '../../../../hooks/TableProvider';

const useTableStyles = () => {
  const { tableId, handRound, mySeatId, handStrength, winners, winnerType, needsChipGathering } = useTableContext();
  const { ref: tableRef, width } = useResizeObserver<HTMLDivElement>();
  const [tableFontSize, setTableFontSize] = React.useState(16);
  const [hasWinners, setHasWinners] = React.useState(false);
  const rotateNumber = useSelector((state: RootState) => selectTableRotateNumber(state, tableId));
  const dispatch = useDispatch();

  // TODO Seat 리팩터링 하면서 이관
  const seatPosition = (seatId: number): number => {
    const totalSeats = 9;
    return (seatId - (mySeatId ?? rotateNumber ?? 0) + totalSeats) % totalSeats;
  };

  useEffect(() => {
    if (mySeatId !== undefined) {
      dispatch(setTableRotateNumber({ tableId, rotateNumber: mySeatId }));
    }
  }, [mySeatId, dispatch]);

  React.useEffect(() => {
    if (!handRound) return;
    if (handRound === 'FLOP') {
      SoundManager._instance?.playSound('card-flip');
    } else if (handRound === 'TURN') {
      SoundManager._instance?.playSound('card-flip');
    } else if (handRound === 'RIVER') {
      SoundManager._instance?.playSound('card-flip');
    }
  }, [handRound]);

  useEffect(() => {
    if (needsChipGathering) {
      _.delay(() => {
        SoundManager._instance?.playSound('chip-gathering');
      }, 500);
    }
  }, [needsChipGathering]);

  React.useEffect(() => {
    if (!winnerType) {
      setHasWinners(false);
      return;
    }
    if (winnerType === 'hero') {
      SoundManager._instance?.playSound('win');
    } else if (winnerType === 'villain') {
      SoundManager._instance?.playSound('villain-win');
    }
    _.delay(() => {
      setHasWinners(true);
    }, DELAY_WINNING_CHIP_INVISIBLE);
  }, [winnerType]);

  React.useEffect(() => {
    if (!width) return setTableFontSize(16);
    if (width > 1880) return setTableFontSize(22.5);
    if (width > 1600) return setTableFontSize(22);
    if (width > 1280) return setTableFontSize(19);
    if (width > 1024) return setTableFontSize(16);
    setTableFontSize(12);
  }, [width]);

  // TODO Card 리팩터링하면서 이관
  const cardResult: (card?: Card) => 'BEST' | 'NOT_BEST' | undefined = card => {
    if (handRound === 'SETTLEMENT' && handStrength && card && winners?.find(winner => winner?.seatId === mySeatId) !== undefined) {
      return handStrength?.bestHand.find(best => best[0] === card[0] && best[1] === card) ? 'BEST' : 'NOT_BEST';
    }
    return undefined;
  };

  return {
    tableRef,
    tableFontSize,
    seatPosition,
    cardResult,
    hasWinners
  };
};

export default useTableStyles;
