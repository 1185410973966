import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';

import HorizontalLayout from '../../layout/HorizontalLayout';
import { useDispatch } from 'react-redux';
import Text from '../../common/text/Text';
import { dismissModal, ModalType, showModal } from '../../../store/slices/modalSlice';
import { XCloseIcon } from '../../common/icon/icons/XCloseIcon';
import { numberToDisplayString } from '../../../utils/StringUtil';
import BuyInSlider from '../../common/slider/BuyInSlider';
import LottieAnimation from '../../common/loading/LottieAnimation';
import LinearLayout from '../../layout/LinearLayout';
import { bpSocketService } from '../../../services/BpWebSocketService';
import { HeaderAction } from '../../../store/slices/streamingTypes';
import BaseButton from '../../common/button/BaseButton';
import { useTableContext } from '../../../hooks/TableProvider';
import { AssetType } from '../../../store/api/responseTypes';
import { useMyBalance } from '../../../hooks/useMyBalance';
import { useCreateConfig } from '../../../hooks/useCreateConfig';
import { addBigNumbers, divideBigNumbers, multiplyBigNumbers, subtractBigNumbers } from '../../../utils/BigNumberUtil';
import useDevice from '../../../hooks/useDevice';

type BuyInOrAddChip = 'BuyIn' | 'AddChip';

const BuyInModal: React.FC = () => {
  const dispatch = useDispatch();
  const { tableSettings, myUserData, mySeatData, myTableAction, betUnit, tableAssetInfo, adjustBetToBetUnit } = useTableContext();
  const currentStack = addBigNumbers(myUserData?.stack, myUserData?.additionalStack).toNumber();
  const minBuyInStack = Math.max(subtractBigNumbers(tableSettings?.buyIn.min, currentStack).toNumber(), betUnit);
  const maxBuyInStack = Math.max(subtractBigNumbers(tableSettings?.buyIn.max, currentStack).toNumber(), 0);
  const interval = useRef<NodeJS.Timeout>();
  const [buyInRemainTime, setAutoStandTimeLimit] = React.useState<number>(0);
  const buyInOrAddChip: BuyInOrAddChip = currentStack > 0 ? 'AddChip' : 'BuyIn';
  const isRealMoneyGame = tableAssetInfo?.type === 'CRYPTO';
  const [inputAmount, setInputAmount] = useState<string>(buyInOrAddChip === 'AddChip' ? maxBuyInStack.toString() : minBuyInStack.toString());
  const [isTimeOver, setTimeOver] = useState<boolean>(false);
  const { isMobile } = useDevice();

  const { myBalance, refetchBalance } = useMyBalance();
  const [assetBalance, setAssetBalance] = React.useState('0.00');
  const { getAssetInfo } = useCreateConfig();
  const tableAssetConfig = getAssetInfo(tableAssetInfo?.name);

  const isInsufficientBalance = isRealMoneyGame ? Number(assetBalance) < minBuyInStack : false;

  const showCashierModal = () => {
    dispatch(showModal(ModalType.CashierModal));
  };

  if (!tableSettings || !myBalance) {
    return <LottieAnimation />;
  }

  const onConfirm = () => {
    if (Number(inputAmount) > 0) {
      bpSocketService.requestChips(adjustBuyInAmount());
    }
    close();
  };

  const onCancel = () => {
    close();
  };

  const close = () => dispatch(dismissModal());

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const buyInUnit = buyInOrAddChip === 'BuyIn' ? tableSettings.blindAmount.big : tableSettings.betUnit;
    let decimalPlaces = 0;
    if (betUnit < 1) {
      decimalPlaces = buyInUnit.toString().split('.')[1]?.length || 0;
    }

    const regex = betUnit < 1 ? /[^0-9.]+/g : /[^0-9]+/g;
    let inputValue = value.replace(regex, '');

    if (betUnit < 1) {
      // 소수점 중복 방지 및 소수점 이하 decimalPlaces까지만 허용
      const parts = inputValue.split('.');
      if (parts.length > 2) {
        inputValue = `${parts[0]}.${parts.slice(1).join('')}`;
      }
      if (parts[1]?.length > decimalPlaces) {
        inputValue = `${parts[0]}.${parts[1].slice(0, decimalPlaces)}`;
      }
      // 입력값이 '0.' 또는 '0.x' 형태일 경우 그대로 유지
      if (value === '0.' || (value.startsWith('0.') && value.length <= decimalPlaces + 2)) {
        inputValue = value;
      }
    }
    setInputAmount(maxBuyInStack > Number(inputValue) ? inputValue : maxBuyInStack.toString());
  };

  const adjustBuyInAmount = () => {
    const minBuyInSize = minBuyInStack;
    const maxBuyInSize = maxBuyInStack;
    const buyInUnit = buyInOrAddChip === 'BuyIn' ? tableSettings.blindAmount.big : tableSettings.betUnit;
    const adjustedBet = multiplyBigNumbers(Math.floor(divideBigNumbers(Number(inputAmount), buyInUnit).toNumber()), buyInUnit).toNumber();
    const finalAdjustedBet = Math.max(minBuyInSize, adjustedBet);
    setInputAmount(finalAdjustedBet.toString());
    return finalAdjustedBet;
  };

  const adjustMaxBuyInAmount = useMemo(() => {
    const maxBuyInAmount = !isRealMoneyGame || isInsufficientBalance ? maxBuyInStack : Math.min(maxBuyInStack, Number(assetBalance));
    const buyInUnit = buyInOrAddChip === 'BuyIn' ? tableSettings.blindAmount.big : tableSettings.betUnit;
    const result = multiplyBigNumbers(Math.floor(divideBigNumbers(Number(maxBuyInAmount), buyInUnit).toNumber()), buyInUnit).toNumber();
    setInputAmount(result.toString());
    return result;
  }, [assetBalance]);

  const getAssetSymbol = () => {
    return isRealMoneyGame ? tableAssetConfig?.asset : 'P';
  };

  useEffect(() => {
    refetchBalance().unwrap();
  }, []);

  useEffect(() => {
    setAssetBalance(myBalance?.balances?.find(item => item.assetName === (tableAssetInfo?.name as AssetType))?.amount.toString() ?? '0.00');
  }, [myBalance]);

  // useEffect(() => {
  //   setInputAmount
  // }, [adjustMaxBuyInAmount]);

  useEffect(() => {
    if (mySeatData?.autoStandAt) {
      const endTime = new Date(mySeatData?.autoStandAt);
      interval.current = setInterval(() => {
        const now = new Date().getTime();
        const diff = endTime.getTime() - now;
        const seconds = Math.floor((diff / 1000) % 60);
        setAutoStandTimeLimit(seconds);
        if (diff < 0) {
          clearInterval(interval.current!);
          setAutoStandTimeLimit(0);
          setTimeOver(true);
        }
      }, 100);
    }
    return () => {
      clearInterval(interval.current!);
    };
  }, [mySeatData]);

  useEffect(() => {
    if (myTableAction === HeaderAction.USER_STAND) {
      setTimeOver(true);
    }
  }, [myTableAction]);

  return (
    <Background>
      <HorizontalLayout margin={'0'}>
        <Text fontStyle={isMobile ? 'TextMd_Semibold' : 'TextXl_Semibold'} textAlign={'start'} textColor={'white'} text={currentStack ? 'Add Chips' : 'Buy-In'} />
        <XCloseIcon onClick={onCancel} />
      </HorizontalLayout>
      {isTimeOver ? (
        <Text marginTop={32} marginBottom={16} fontStyle={isMobile ? 'TextMd_Medium' : 'TextXl_Medium'} text={'You did not act in time. Buy-in has been cancelled.'} textColor={'white'} />
      ) : (
        <>
          <Text fontStyle={isMobile ? 'TextLg_Bold' : 'DisplayXs_Bold'} textAlign={'center'} textColor={'white'} marginTop={24} marginBottom={24} text={tableSettings?.name ?? ''} />
          {isInsufficientBalance && <StyledErrorText>{`Insufficient ${tableAssetInfo?.name} Balance`}</StyledErrorText>}
          {isRealMoneyGame && (
            <StyledBalanceView alignItem={'center'} backgroundColor={'gray750'} padding={8} margin={'0'} borderRadius={8}>
              <StyledCurrencyText>
                <Text text={tableAssetInfo?.name} fontStyle={'TextMd_Medium'} textColor={'gray300'} />
              </StyledCurrencyText>
              <StyledCurrencyText>
                <Text text={assetBalance} fontStyle={'TextMd_Semibold'} textColor={'gray300'} />
              </StyledCurrencyText>
            </StyledBalanceView>
          )}
          <HorizontalLayout margin={isRealMoneyGame ? '16px 0 0 0' : '40px 0 0 0'}>
            <BaseButton
              textStyle={isMobile ? 'TextSmall_Bold' : 'TextMd_Bold'}
              textColor="white"
              w={50}
              size={'btn36'}
              onClick={() => {
                setInputAmount(minBuyInStack.toString());
              }}
              color={'actionGray'}
            >
              MIN
            </BaseButton>
            <BuyInSlider
              value={Number(inputAmount)}
              max={adjustMaxBuyInAmount}
              min={minBuyInStack}
              step={buyInOrAddChip === 'BuyIn' ? tableSettings.blindAmount.big : tableSettings.betUnit}
              onChange={value => {
                setInputAmount(value.toString());
              }}
            />
            <BaseButton
              textStyle={isMobile ? 'TextSmall_Bold' : 'TextMd_Bold'}
              textColor="white"
              w={50}
              size={'btn36'}
              onClick={() => {
                setInputAmount(adjustMaxBuyInAmount.toString());
              }}
              color={'actionGray'}
            >
              MAX
            </BaseButton>
          </HorizontalLayout>
          <HorizontalLayout margin={'8px 0 0 0'}>
            <Text fontStyle={isMobile ? 'TextMd_Medium' : 'TextLg_Medium'} text={`${tableAssetConfig?.assetType !== 'CRYPTO' ? 'P' : ''}${numberToDisplayString(minBuyInStack)}`} textColor={'white'} lineHeight={28} />
            <Text fontStyle={isMobile ? 'TextMd_Medium' : 'TextLg_Medium'} text={`${tableAssetConfig?.assetType !== 'CRYPTO' ? 'P' : ''}${numberToDisplayString(adjustMaxBuyInAmount)}`} textColor={'white'} />
          </HorizontalLayout>
          <LinearLayout gap={4} marginTop={16} marginBottom={isMobile ? 16 : 25}>
            <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd'} text={'Amount'} textColor={'gray300'} />
            <InputContainer>
              <Text fontStyle={isMobile ? 'TextMd_Semibold' : 'TextLg_Semibold'} text={getAssetSymbol()} textColor={'primary500'} />
              <StyledBuyInInput value={inputAmount} type={'text'} onChange={handleInputChange} onBlur={adjustBuyInAmount} />
            </InputContainer>
          </LinearLayout>
        </>
      )}
      {mySeatData?.autoStandAt && (
        <TextContainer>
          <Text fontStyle={'TextSmall_Medium'} text={'Remaining time (sec.):'} textColor={'gray400'} />
          <Text fontStyle={'TextMd_Semibold'} text={String(buyInRemainTime)} textColor={'error500'} />
        </TextContainer>
      )}
      <HorizontalLayout margin={'0'} gap={12}>
        {isTimeOver ? (
          <BaseButton textStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} size={isMobile ? 'btn40' : 'btn60'} textColor={'white'} text={'Okay'} color={'primary'} onClick={onCancel} />
        ) : (
          <>
            <BaseButton textStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} size={isMobile ? 'btn40' : 'btn60'} textColor={'white'} text={'Cancel'} color={'gray'} onClick={onCancel} />
            {isInsufficientBalance ? (
              <BaseButton textStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} size={isMobile ? 'btn40' : 'btn60'} textColor={'white'} text={'Cashier'} color={'primary'} onClick={showCashierModal} />
            ) : (
              <BaseButton textStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} size={isMobile ? 'btn40' : 'btn60'} textColor={'white'} text={buyInOrAddChip === 'AddChip' ? 'Add Chips' : 'Buy In'} color={'primary'} onClick={onConfirm} disabled={Number(inputAmount) < minBuyInStack} />
            )}
          </>
        )}
      </HorizontalLayout>
    </Background>
  );
};

export default BuyInModal;

const StyledBalanceView = styled(HorizontalLayout)`
  padding: 8px !important;
`;

const StyledErrorText = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  color: ${({ theme }) => theme.colors.error500};
  margin-bottom: 4px;
  width: 100%;
  text-align: center;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
  }
`;

const Background = styled.div`
  width: 500px;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 32px 40px;
  border-radius: 12px;
  @media (max-width: 500px) {
    padding: 32px 20px;
  }
`;

const CancelButton = styled.button`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  color: ${({ theme }) => theme.colors.white};
  flex: 1;
  min-height: 60px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.gray700};

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray600};
  }
`;

const ConfirmButton = styled(CancelButton)`
  background-color: ${({ theme }) => theme.colors.primary600};
  transition: background-color 0.1s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary500};
  }
`;

const InputContainer = styled.div`
  width: 200px;
  height: 52px;
  display: flex;
  align-self: center;
  justify-content: space-between;
  align-items: start;
  flex-direction: row;
  gap: 24px;
  border-radius: 8px;
  padding: 0 16px;
  background-color: ${({ theme }) => theme.colors.gray900};
  @media (max-width: 768px) {
    height: 50px;
  }
`;

export const StyledBuyInInput = styled.input`
  height: 100%;
  width: 100%;
  flex: 1;
  text-align: end;
  align-self: center;
  border-radius: 6px;
  ${({ theme }) => theme.textStyles.DisplayXs_Semibold};
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary500};
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextXl_Semibold};
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  align-self: center;
  gap: 4px;
`;

const StyledCurrencyText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 24px;
  padding: 0 8px;
`;
