import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';

const breakpoint = {
  mobile: 0,
  tablet: 768,
  desktop: 1920,
  desktopMedium: 1240,
  desktopSmall: 992
};

export const device = {
  mobileSmall: `(max-width: 320px)`,
  mobile: `(min-width: ${breakpoint.mobile}px) and (max-width: ${breakpoint.tablet}px)`,
  tablet: `(min-width: ${breakpoint.tablet + 1}px) and (max-width: ${breakpoint.desktopSmall}px)`,
  desktopSmall: `(min-width: ${breakpoint.desktopSmall + 1}px) and (max-width: ${breakpoint.desktopMedium}px)`,
  desktop: `(min-width: ${breakpoint.desktopMedium + 1}px)`
};

export function useDevice() {
  const isDesktop = useMediaQuery({ query: `${device.desktop}` });
  const isDesktopSmall = useMediaQuery({ query: `${device.desktopSmall}` });
  const isTablet = useMediaQuery({ query: `${device.tablet}` });
  const isMobile = useMediaQuery({ query: `${device.mobile}` });
  const isMobileSmall = useMediaQuery({ query: `${device.mobileSmall}` });

  const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  useEffect(() => {
    const handleResize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { isDesktop, isDesktopSmall, isTablet, isMobile, isMobileSmall, screenSize };
}

export default useDevice;
