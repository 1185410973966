import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { userApi } from '../api/user';
import { authApi } from '../api/auth';
import { Setting, User } from '../api/responseTypes';
import { TableUserSettingRequestDto } from './streamingTypes';
import { createSelector } from 'reselect';
import { utilApi } from '../api/util';
import { holdemApi } from '../api/holdem';

export type SettingPages = 'Profile' | 'Settings' | 'Wallet' | 'Log out' | 'History' | 'Lobby';
export type PolicyPages = 'Terms of Service' | 'Privacy Policy' | 'Cookie Policy' | 'House Rules' | 'Responsible Gaming';
export const supportNavigation: SettingPages[] = ['Lobby', 'Profile', 'Settings', 'Wallet', 'History', 'Log out'];
export const supportSettingPages: SettingPages[] = ['Profile', 'Settings', 'Wallet', 'History', 'Log out']; // ['Profile', 'Settings', 'Log out']
export const supportPolicyPages: PolicyPages[] = ['Terms of Service', 'Privacy Policy', 'Cookie Policy', 'House Rules', 'Responsible Gaming'];

type AuthState = {
  isUnauthorized: boolean;
  user: User | undefined;
  email: string | null;
  isEmailValid: boolean;
  lastSettingPage: SettingPages;
  lastPolicyPage: PolicyPages;
  lastRequestedTime: number;
  lastVerifyCodeTime: number;
  verifyCodeErrorCount: number;
  requestCodeCount: number;
  tableUserSetting: Record<number, Setting>;
  redirectUrl: string | undefined;
};

const validateEmail = (email: string) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(email);
};

const initialAuthState = {
  isUnauthorized: false,
  user: undefined,
  email: null,
  isEmailValid: true,
  lastSettingPage: 'Profile',
  lastPolicyPage: 'Terms of Service',
  lastRequestedTime: 0,
  lastVerifyCodeTime: 0,
  tableUserSetting: {},
  verifyCodeErrorCount: 0,
  requestCodeCount: 0,
  redirectUrl: undefined
} as AuthState;

const authSlice = createSlice({
  name: 'user',
  initialState: initialAuthState,
  reducers: {
    setUnauthorized(state, action: PayloadAction<boolean>) {
      state.isUnauthorized = action.payload;
    },
    setTableSetting: (state, action: PayloadAction<TableUserSettingRequestDto>) => {
      const { tableId, setting } = action.payload;
      if (tableId) {
        state.tableUserSetting[Number(tableId)] = setting;
      }
    },
    setRedirectUrl: (state, action: PayloadAction<string | undefined>) => {
      state.redirectUrl = action.payload;
    },
    setEmail: (state, action: PayloadAction<string | null>) => {
      state.email = action.payload;
      state.isEmailValid = action.payload === null ? true : validateEmail(action.payload);
    },
    setEmailValidity: (state, action: PayloadAction<boolean>) => {
      state.isEmailValid = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<SettingPages>) => {
      state.lastSettingPage = action.payload;
    },
    setPolicyTab: (state, action: PayloadAction<PolicyPages>) => {
      state.lastPolicyPage = action.payload;
    },
    incrementRequestCodeCount(state) {
      state.requestCodeCount += 1;
      state.lastRequestedTime = new Date().getTime();
    },
    incrementVerifyCodeErrorCount(state) {
      state.verifyCodeErrorCount += 1;
      state.lastVerifyCodeTime = new Date().getTime();
    },
    resetVerifyCodeErrorCount(state) {
      state.verifyCodeErrorCount = 0;
    },
    resetRequestCodeCount(state) {
      state.requestCodeCount = 0;
    }
  },
  extraReducers: builder => {
    builder.addMatcher(userApi.endpoints.getUserProfile.matchFulfilled, (state, { payload }) => {
      state.user = payload.data;
      state.requestCodeCount = 0;
      state.verifyCodeErrorCount = 0;
      state.lastRequestedTime = 0;
      state.lastVerifyCodeTime = 0;
    });
    builder.addMatcher(userApi.endpoints.updateProfile.matchFulfilled, (state, { payload }) => {
      state.user = payload.data;
      state.requestCodeCount = 0;
      state.verifyCodeErrorCount = 0;
      state.lastRequestedTime = 0;
      state.lastVerifyCodeTime = 0;
    });
    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, state => {
      state.user = undefined;
    });
    builder.addMatcher(userApi.endpoints.getUserProfile.matchRejected, state => {
      state.user = undefined;
    });
    builder.addMatcher(authApi.endpoints.refreshToken.matchRejected, state => {
      state.user = undefined;
    });
    builder.addMatcher(utilApi.endpoints.countryList.matchRejected, (state, { payload }) => {
      if (payload?.status === 401) {
        state.user = undefined;
      }
    });
    builder.addMatcher(utilApi.endpoints.emojiList.matchRejected, (state, { payload }) => {
      if (payload?.status === 401) {
        state.user = undefined;
      }
    });
    builder.addMatcher(holdemApi.endpoints.lobbyTableList.matchRejected, (state, { payload }) => {
      if (payload?.status === 401) {
        state.user = undefined;
      }
    });
    builder.addMatcher(holdemApi.endpoints.holdemTableConfig.matchRejected, (state, { payload }) => {
      if (payload?.status === 401) {
        state.user = undefined;
      }
    });
  }
});

export const { setUnauthorized } = authSlice.actions;
export const { incrementVerifyCodeErrorCount, resetVerifyCodeErrorCount, incrementRequestCodeCount, resetRequestCodeCount } = authSlice.actions;
export const { setActiveTab } = authSlice.actions;
export const { setPolicyTab } = authSlice.actions;
export const { setEmail } = authSlice.actions;
export const { setRedirectUrl } = authSlice.actions;

export default authSlice;

export const selectCurrentUser = (state: RootState) => state.authSlice.user;
export const selectCurrentEmail = (state: RootState) => state.authSlice.email;
export const selectIsEmailValid = (state: RootState) => state.authSlice.isEmailValid;
export const selectLastPage = (state: RootState) => state.authSlice.lastSettingPage;
export const selectLastPolicyPage = (state: RootState) => state.authSlice.lastPolicyPage;
export const selectRedirectUrl = (state: RootState) => state.authSlice.redirectUrl;
const getTableUserSettings = (state: RootState) => state.authSlice.tableUserSetting;
export const selectTableUserSettingByTableId = createSelector([getTableUserSettings, (_: RootState, tableId: number) => tableId], (tableUserSettings, tableId) => tableUserSettings[tableId] || undefined);

export const selectLastRequestedTime = (state: RootState) => state.authSlice.lastRequestedTime;
export const selectLastVerifyCodeTime = (state: RootState) => state.authSlice.lastVerifyCodeTime;
export const selectVerifyCodeErrorCount = (state: RootState) => state.authSlice.verifyCodeErrorCount ?? 0;
export const selectRequestCodeCount = (state: RootState) => state.authSlice.requestCodeCount ?? 0;
export const selectIsUnauthorized = (state: RootState) => state.authSlice.isUnauthorized;
