import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingPage from '../components/common/loading/LoadingPage';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { selectRedirectUrl, setRedirectUrl } from '../store/slices/authSlice';
import { logDebug } from '../utils/ConsoleUtil';

const AuthScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { result } = useParams<{ result: string }>();
  const navigate = useNavigate();
  const redirectUrl = useSelector(selectRedirectUrl);

  useEffect(() => {
    logDebug(redirectUrl);
    _.delay(() => {
      if (result === 'success') {
        if (redirectUrl?.includes('holdem')) {
          navigate(redirectUrl);
        } else {
          navigate('/lobby');
        }
      } else if (result === 'refresh') {
        navigate(-1);
      } else {
        navigate('/error');
      }
      dispatch(setRedirectUrl(undefined));
    }, 500);
  }, [result, navigate]);

  return <LoadingPage />;
};

export default AuthScreen;
