import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { ArrowUp } from '../../common/icon/icons/ArrowUp';
import { ArrowDown } from '../../common/icon/icons/ArrowDown';
import { ExternalLink } from '../../common/icon/icons/ExternalLink';
import { Tab, TabContainer } from './MenuTab';
import LinearLayout from '../../layout/LinearLayout';
import useDevice from '../../../hooks/useDevice';
import HorizontalLayout from '../../layout/HorizontalLayout';
import Text from '../../common/text/Text';
import { StatusTextProps, WalletTransaction } from '../../../store/api/dto';
import useBottomReached from '../../../hooks/useInfinityScroll';
import { getTimestamp, toFixedFloor } from '../../../utils/StringUtil';
import { useLazyTransactionListQuery } from '../../../store/api/assets';
import { selectLastTransactionIdByType, selectTransactionsByType, selectWalletHasMore } from '../../../store/slices/assetSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { logDebug, logError } from '../../../utils/ConsoleUtil';

const TransactionTemplate = () => {
  const { isMobile } = useDevice();

  const [activeTab, setActiveTab] = React.useState<'ALL' | 'DEPOSIT' | 'WITHDRAWAL'>('ALL');
  const lastId = useSelector((state: RootState) => selectLastTransactionIdByType(state, activeTab));
  const transactions = useSelector((state: RootState) => selectTransactionsByType(state, activeTab));
  const hasMore = useSelector(selectWalletHasMore);

  const [fetchTransactionList, { isLoading, isFetching }] = useLazyTransactionListQuery();
  const { isReached, loadMoreRef } = useBottomReached();

  useEffect(() => {
    fetchTransactionList({ type: 'ALL', limit: 20 })
      .unwrap()
      .then(() => {
        logDebug('Transaction list fetched');
      })
      .catch(() => {
        logError('Transaction list fetch failed');
      });
  }, []);

  useEffect(() => {
    if (hasMore && isReached && !isFetching) {
      fetchTransactionList({ lastId: lastId ? Number(lastId) : undefined, type: activeTab, limit: 20 })
        .unwrap()
        .then(() => {
          logDebug('Transaction list fetched');
        })
        .catch(() => {
          logError('Transaction list fetch failed');
        });
    }
  }, [isReached, isFetching, transactions, activeTab]);

  const getIcon = (tr: WalletTransaction) => {
    if (tr.type === 'WITHDRAWAL') {
      return <ArrowDown color={'error500'} />;
    } else {
      return <ArrowUp color={'primary500'} />;
    }
  };

  const showExplorer = (explorerLink: string) => {
    if (explorerLink) {
      window.open(explorerLink, '_blank');
    }
  };

  const getStatus = (status: string) => {
    switch (status) {
      case 'PENDING':
        return 'Pending';
      case 'COMPLETED':
        return 'Completed';
      case 'FAILED':
        return 'Failed';
      case 'REQUEST':
        return 'Requested';
      default:
        return status;
    }
  };

  const getAsset = (asset: string): string => {
    switch (asset) {
      case 'USDT':
        return ' USDT';
      case 'Tether':
        return ' USDT';
      default:
        return asset;
    }
  };
  return (
    <LinearLayout backgroundColor={'gray800'} borderRadius={12} padding={40} gap={0} marginTop={8}>
      <TabContainer>
        <Tab onClick={() => setActiveTab('ALL')} aria-selected={activeTab === 'ALL'}>
          All
        </Tab>
        <Tab onClick={() => setActiveTab('DEPOSIT')} aria-selected={activeTab === 'DEPOSIT'}>
          Deposit
        </Tab>
        <Tab onClick={() => setActiveTab('WITHDRAWAL')} aria-selected={activeTab === 'WITHDRAWAL'}>
          Withdrawal
        </Tab>
      </TabContainer>
      <HorizontalLayout margin={'16px 0 0 0'} gap={0}>
        <Text fontStyle={'TextSmall_Medium'} textColor={'gray400'} textAlign={'start'} text={'Date'} />
        <Text fontStyle={'TextSmall_Medium'} textColor={'gray400'} textAlign={'start'} text={'Amount'} />
      </HorizontalLayout>
      <TransactionList>
        {transactions.map((transaction, i) => (
          <TransactionItem key={transaction.id}>
            <ColumnBox>
              <RowBox
                hasLink={Boolean(transaction.txId)}
                onClick={() => {
                  transaction.txId && showExplorer(transaction.explorerLink);
                }}
              >
                <StatusText status={transaction.status}>{getStatus(transaction.status)}</StatusText>
                {transaction.txId && <ExternalLink w={isMobile ? 16 : 20} h={isMobile ? 16 : 20} />}
              </RowBox>
              <DateText>{getTimestamp(transaction.createdAt)}</DateText>
            </ColumnBox>
            <RowBox hasLink={false}>
              <PriceText>{toFixedFloor(Number(transaction.amount.totalAmount), transaction.asset.displayDecimals ?? 2) + getAsset(transaction.asset.name)}</PriceText>
              {getIcon(transaction)}
            </RowBox>
          </TransactionItem>
        ))}
        {transactions.filter(transaction => activeTab === 'ALL' || (activeTab === 'DEPOSIT' && transaction.type === 'DEPOSIT') || (activeTab === 'WITHDRAWAL' && transaction.type === 'WITHDRAWAL')).length === 0 && (
          <Text fontStyle={'TextMd_Medium'} textColor={'gray400'} textAlign={'center'} text={'You have no transactions'} marginTop={160} marginBottom={160} />
        )}
        <div ref={loadMoreRef} />
      </TransactionList>
    </LinearLayout>
  );
};

export default TransactionTemplate;

const TransactionList = styled.div`
  overflow-y: auto;
  max-height: 80vh;
  min-height: 344px;
  width: 100%;
`;

const TransactionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  border-bottom-color: ${({ theme }) => theme.colors.gray700};
  padding-top: 16px;
  padding-bottom: 16px;
`;

const ColumnBox = styled.div`
  flex-direction: column;
  align-items: start;
`;

const RowBox = styled.div<{ hasLink: boolean }>`
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: ${({ hasLink }) => (hasLink ? 'pointer' : 'default')};
`;

const StatusText = styled.div<StatusTextProps>`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  color: ${({ status, theme }) => (status === 'PENDING' || status === 'REQUEST' ? theme.colors.warning400 : status === 'COMPLETED' ? theme.colors.success500 : status === 'FAILED' ? theme.colors.error500 : 'defaultColor')};
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Semibold};
  }
`;

const DateText = styled.div`
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  color: ${({ theme }) => theme.colors.gray400};
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextXs_Medium};
  }
`;

const PriceText = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
  color: ${({ theme }) => theme.colors.white};
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Semibold};
  }
`;
