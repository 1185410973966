import React from 'react';
import { ClickableIconProps, IconProps } from '../types';

export const ArrowLeft = React.memo<ClickableIconProps>(({ w = 24, h = 24, color = 'white', onClick }) => (
  <svg width={w} height={h} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} cursor={'pointer'}>
    <path d="M19 12H5M5 12L12 19M5 12L12 5" stroke="#98A2B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
));

ArrowLeft.displayName = 'ArrowLeft';
