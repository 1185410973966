import React from 'react';
import styled from '@emotion/styled';
import { Colors } from '../../../styles/Colors';
import { TextStyles } from '../../../styles/textStyles';

interface HorizontalTitleDescriptionProps {
  label: string | undefined;
  value: string | undefined;
  borderColor?: keyof typeof Colors;
  labelFontStyle?: keyof typeof TextStyles;
  valueFontStyle?: keyof typeof TextStyles;
  labelColor?: keyof typeof Colors;
  valueColor?: keyof typeof Colors;
}

const TitleValueHorizontal: React.FC<HorizontalTitleDescriptionProps> = ({ label, value, borderColor, labelFontStyle = 'TextMd_Medium', valueFontStyle = 'TextMd_Medium', labelColor = 'gray300', valueColor = 'white' }) => {
  return (
    <Section>
      <Label labelFontStyle={labelFontStyle} labelColor={labelColor}>
        {label ?? ''}
      </Label>
      <Value borderColor={borderColor} valueFontStyle={valueFontStyle} valueColor={valueColor}>
        {value ?? ''}
      </Value>
    </Section>
  );
};

const Section = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Label = styled.span<{ labelFontStyle: keyof typeof TextStyles; labelColor: keyof typeof Colors }>`
  ${({ theme, labelFontStyle }) => theme.textStyles[labelFontStyle]};
  color: ${({ theme, labelColor }) => theme.colors[labelColor]};
  user-select: none;
`;

const Value = styled.span<{ borderColor?: keyof typeof Colors; valueFontStyle: keyof typeof TextStyles; valueColor: keyof typeof Colors }>`
  ${({ theme, valueFontStyle }) => theme.textStyles[valueFontStyle]};
  color: ${({ theme, valueColor }) => theme.colors[valueColor]};
  ${({ theme, borderColor }) => (borderColor ? `background-color: ${theme.colors[borderColor]}; border-radius: 100px; padding: 4px 12px;` : 'padding: 0;')};
  user-select: none;
`;

export default TitleValueHorizontal;
