import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { LeftText } from '../../common/text/styles';
import LinearLayout from '../../layout/LinearLayout';
import TextInput from '../../common/input/TextInput';
import { useHoldemTableCreateMutation, useLazyLobbyTableListQuery } from '../../../store/api/holdem';
import { TableCreateAssetConfig, TableCreateAssetValueConfig, TableCreateResponse } from '../../../store/api/responseTypes';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { splitEmail } from '../../../utils/StringUtil';
import { showFailToast } from '../../common/toast/BpToast';
import { isDataResponse } from '../../../store/api/api';
import { useCreateConfig } from '../../../hooks/useCreateConfig';
import LottieAnimation from '../../common/loading/LottieAnimation';
import StakesSelect from '../../common/select/StakesSelect';
import NumberSelect from '../../common/select/NumberSelect';
import HorizontalLayout from '../../layout/HorizontalLayout';
import TitleValueHorizontal from '../../common/text/TitleValueHorizontal';
import Text from '../../common/text/Text';
import { ReactComponent as HelpCircle } from '../../../assets/img/help-circle.svg';
import useDevice from '../../../hooks/useDevice';
import BaseButton from '../../common/button/BaseButton';
import AssetSelect from '../../common/select/AssetSelect';
import ToggleSwitch from '../../common/switch/ToggleSwitch';
import { CopyIcon } from '../../common/icon';
import { showSuccessToast } from '../../common/toast/BpToast';
import { logError } from '../../../utils/ConsoleUtil';

interface MyTableCreatePlayMoneyTemplateProps {
  onCreateSuccess?: (response: TableCreateResponse) => void;
}

const MAX_TITLE = 30;
export const MAX_PASSWORD = 4;

const CreateTableTemplate: React.FC<MyTableCreatePlayMoneyTemplateProps> = ({ onCreateSuccess }) => {
  const { isMobile } = useDevice();
  const { user } = useUserProfile();
  const { tableCreateConfig, getAssets, getAssetConfig, getStakesBySeq } = useCreateConfig();

  const [selectedAsset, setSelectedAsset] = useState<TableCreateAssetConfig | undefined>(getAssets() ? getAssets()![0] : undefined);
  const [selectedStakes, setSelectedStakes] = useState<TableCreateAssetValueConfig | undefined>(getAssetConfig(selectedAsset?.asset) ? getAssetConfig(selectedAsset?.asset)![0] : undefined);
  const [selectedMinimumPlayers, setMinimumPlayers] = useState<number>(2);

  const [tableTitle, setTitle] = useState<string>(splitEmail(user?.name) + '`s Table');
  const [password, setPassword] = useState(''); // generateRandomPassword()
  const [isCreateValid, setCreateValid] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showRakeTooltip, setRakeTooltip] = useState(false);
  const [createTable] = useHoldemTableCreateMutation();
  const [refetchLobbyTableList] = useLazyLobbyTableListQuery();

  const [isRealMoney, setRealMoney] = useState(selectedAsset?.assetType === 'CRYPTO');
  const [isRakeEnabled, setRakeEnabled] = useState(false);
  const [selectedRake, setSelectedRake] = useState<number>(3);
  const rakeOptions = [1, 2, 3, 4, 5];

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
    if (newValue.length > MAX_TITLE) {
      newValue = newValue.substring(0, MAX_TITLE);
    }
    setTitle(newValue);
  };

  const handleShowTooltip = (type: 'rake' | 'minPlayer') => {
    if (type === 'rake') {
      setShowTooltip(false);
      setRakeTooltip(!showRakeTooltip);
    } else {
      setRakeTooltip(false);
      setShowTooltip(!showTooltip);
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
    // newValue = newValue.replace(/\D/g, ''); // Remove non-numeric characters
    if (newValue.length > MAX_PASSWORD) {
      newValue = newValue.substring(0, MAX_PASSWORD);
    }
    setPassword(newValue);
  };

  const handleCreateTable = async () => {
    if (selectedAsset && tableTitle && tableTitle.length > 0 && selectedStakes && (password.length === 4 || password.length === 0)) {
      const tableConfig: any = {
        assetId: selectedAsset.assetId,
        tableTitle: tableTitle,
        tablePassword: password,
        minPlayersCnt: selectedMinimumPlayers,
        stakesSeq: selectedStakes?.seq,
        rakeRate: isRealMoney && isRakeEnabled ? selectedRake / 100 : undefined
      };

      if (isRealMoney && isRakeEnabled) {
        tableConfig.rake = selectedRake;
      }

      const response = await createTable(tableConfig);
      if (isDataResponse(response)) {
        if (onCreateSuccess) {
          refetchLobbyTableList();
          onCreateSuccess(response.data.data! as unknown as TableCreateResponse);
        }
      } else {
        showFailToast('Failed to create table');
      }
    }
  };

  const handleAssetChange = (assetConfig: TableCreateAssetConfig) => {
    setSelectedAsset(assetConfig);
    setSelectedStakes(getAssetConfig(assetConfig.asset) ? getAssetConfig(assetConfig.asset)![0] : undefined);
  };

  const handleStakesChange = (option: { value: number; label: string }) => {
    const stakesConfig = getStakesBySeq(option.value);
    setSelectedStakes(stakesConfig ?? undefined);
  };

  const handleNumberChange = (option: number) => {
    setMinimumPlayers(option);
  };

  const getErrorMessage = (password: string) => {
    if (/\D/g.test(password)) {
      return 'Enter numbers only (eg. 1234)';
    } else if (!/\D/g.test(password) && password.length !== 4 && password.length !== 0) {
      return 'Password must be 4 digits';
    } else {
      return '';
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(password);
      showSuccessToast('Password is copied.');
    } catch (err) {
      logError('Failed to copy:', err);
    }
  };

  const handleRakeChange = (value: number) => {
    setSelectedRake(value);
  };

  useEffect(() => {
    setCreateValid(tableTitle.length > 0 && (password.length === 0 || /^\d{4}$/.test(password)) && selectedStakes !== null && selectedMinimumPlayers > 0);
  }, [tableTitle, password, selectedStakes]);

  useEffect(() => {
    if (getAssets()) {
      setSelectedAsset(getAssets()![0]);
      setSelectedStakes(getAssetConfig(getAssets()![0].asset)![0]);
    }
  }, [getAssets()]);

  useEffect(() => {
    if (selectedAsset) {
      setRealMoney(selectedAsset.assetType === 'CRYPTO');
    }
  }, [selectedAsset]);

  if (!tableCreateConfig) {
    return <LottieAnimation />;
  }

  const reactiveView = () => {
    if (isMobile) {
      return (
        <>
          <LinearLayout gap={4}>
            <LeftText fontStyle={'TextXs_Medium'} text={'Chips'} />
            {getAssets() !== undefined && <AssetSelect options={getAssets()!} onChange={handleAssetChange} />}
          </LinearLayout>
          <LinearLayout gap={4}>
            <LeftText fontStyle={'TextXs_Medium'} text={'Stakes'} />
            {getAssetConfig(selectedAsset?.asset) && <StakesSelect assetOptions={getAssetConfig(selectedAsset?.asset)!} assetConfig={selectedAsset!} onChange={handleStakesChange} />}
          </LinearLayout>
          <LinearLayout gap={4}>
            <HorizontalLayout margin={'0'} justifyContent={'start'} alignItem={'center'} gap={4}>
              <LeftText fontStyle={'TextXs_Medium'} text={'Minimum Players'} />
              <StyledTooltip>
                <HelpCircle onClick={() => handleShowTooltip('minPlayer')} />
                {showTooltip && (
                  <Tooltip isRealMoney={isRealMoney} isRakeEnabled={isRakeEnabled}>
                    "Minimum players" means the smallest number of players needed to start the game. Once this number of players joins the table, the game will begin automatically.
                  </Tooltip>
                )}
              </StyledTooltip>
            </HorizontalLayout>
            {tableCreateConfig.minPlayers && <NumberSelect options={tableCreateConfig.minPlayers} onChange={handleNumberChange} />}
          </LinearLayout>
          <LinearLayout gap={4}>
            <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
              <LeftText fontStyle={'TextXs_Medium'} text={'Password'} />
            </HorizontalLayout>
            <TextInput onChange={handlePasswordChange} value={password} placeholder={'Numbers only (eg. 1234)'} />
            <ErrorMessage>{getErrorMessage(password)}</ErrorMessage>
          </LinearLayout>
        </>
      );
    } else {
      return (
        <>
          <HorizontalLayout margin={'0'} justifyContent={'space-between'} gap={16}>
            <LinearLayout gap={4}>
              <LeftText fontStyle={'TextMd_Medium'} text={'Chips'} />
              {getAssets() !== undefined && <AssetSelect options={getAssets()!} onChange={handleAssetChange} />}
            </LinearLayout>
            <LinearLayout gap={4}>
              <LeftText fontStyle={'TextMd_Medium'} text={'Stakes'} />
              {getAssetConfig(selectedAsset?.asset) && <StakesSelect assetOptions={getAssetConfig(selectedAsset?.asset)!} assetConfig={selectedAsset!} onChange={handleStakesChange} />}
            </LinearLayout>
          </HorizontalLayout>
          <HorizontalLayout margin={'0'} justifyContent={'space-between'} gap={16} alignItem={'start'}>
            <LinearLayout gap={4}>
              <HorizontalLayout margin={'0'} justifyContent={'start'} alignItem={'center'} gap={4}>
                <LeftText fontStyle={'TextMd_Medium'} text={'Minimum Players'} />
                <StyledTooltip>
                  <HelpCircle onClick={() => handleShowTooltip('minPlayer')} />
                  {showTooltip && (
                    <Tooltip isRealMoney={isRealMoney} isRakeEnabled={isRakeEnabled}>
                      "Minimum players" means the smallest number of players needed to start the game. Once this number of players joins the table, the game will begin automatically.
                    </Tooltip>
                  )}
                </StyledTooltip>
              </HorizontalLayout>
              {tableCreateConfig.minPlayers && <NumberSelect options={tableCreateConfig.minPlayers} onChange={handleNumberChange} />}
            </LinearLayout>
            <LinearLayout gap={4}>
              <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
                <LeftText fontStyle={'TextMd_Medium'} text={'Password'} />
              </HorizontalLayout>
              <TextInput onChange={handlePasswordChange} value={password} placeholder={'Numbers only (eg. 1234)'} />
              <ErrorMessage>{getErrorMessage(password)}</ErrorMessage>
            </LinearLayout>
          </HorizontalLayout>
        </>
      );
    }
  };

  return (
    <ModalContainer>
      <LinearLayout gap={16}>
        <LinearLayout gap={4}>
          <HorizontalLayout gap={4} margin={'0'} justifyContent={'start'}>
            <LeftText fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} text={'Table Title'} />
            <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} text={'*'} textColor={'error500'} />
          </HorizontalLayout>
          <TextInput type="text" onChange={handleTitleChange} value={tableTitle} />
          {tableTitle.length < 1 && <ErrorMessage>Please enter a table title</ErrorMessage>}
        </LinearLayout>
        {reactiveView()}
        {isRealMoney && (
          <LinearLayout gap={12} marginBottom={4}>
            <HorizontalLayout gap={4} margin={'0'} justifyContent={'space-between'}>
              <HorizontalLayout margin={'0'} justifyContent={'start'} gap={4}>
                <LeftText fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} text={'Charge Rake'} />
                <StyledTooltip>
                  <HelpCircle onClick={() => handleShowTooltip('rake')} />
                  {showRakeTooltip && (
                    <RakeTooltip isRakeEnabled={isRakeEnabled}>The rake is a small percentage taken from each pot. When you create a private poker room, you have the opportunity to collect the rake as the host. The total rake collected is split 70/30 between the host and Basepoker.</RakeTooltip>
                  )}
                </StyledTooltip>
              </HorizontalLayout>
              <ToggleSwitch isActive={isRakeEnabled} onClick={() => setRakeEnabled(!isRakeEnabled)} />
            </HorizontalLayout>
            {isRakeEnabled && (
              <HorizontalLayout gap={10} margin={'0'}>
                {rakeOptions.map(option => (
                  <RakeOption key={option} selected={option === selectedRake} onClick={() => handleRakeChange(option)}>
                    {option}%
                  </RakeOption>
                ))}
              </HorizontalLayout>
            )}
          </LinearLayout>
        )}
        {!isMobile && (
          <SummaryContainer>
            <Text textAlign={'start'} fontStyle={'TextMd_Bold'} textColor={'gray300'} text={tableTitle} />
            <TitleValueHorizontal
              label={'Stakes'}
              value={`${selectedAsset?.assetType !== 'CRYPTO' ? 'P' : ''}${selectedStakes?.smallBlindAmount} / ${selectedAsset?.assetType !== 'CRYPTO' ? 'P' : ''}${selectedStakes?.bigBlindAmount} ${selectedAsset?.assetType === 'CRYPTO' ? selectedAsset.asset : ''}`}
              labelFontStyle={'TextMd_Medium'}
              labelColor={'gray400'}
              valueColor={'gray300'}
              valueFontStyle={'TextMd_Medium'}
            />
            <TitleValueHorizontal
              label={'Buy-in'}
              value={`Min ${selectedAsset?.assetType !== 'CRYPTO' ? 'P' : ''}${selectedStakes?.minBuyInAmount} - Max ${selectedAsset?.assetType !== 'CRYPTO' ? 'P' : ''}${selectedStakes?.maxBuyInAmount} ${selectedAsset?.assetType === 'CRYPTO' ? selectedAsset.asset : ''}`}
              labelFontStyle={'TextMd_Medium'}
              labelColor={'gray400'}
              valueColor={'gray300'}
              valueFontStyle={'TextMd_Medium'}
            />
            <TitleValueHorizontal label={'Min. players'} value={selectedMinimumPlayers.toString()} labelFontStyle={'TextMd_Medium'} labelColor={'gray400'} valueColor={'gray300'} valueFontStyle={'TextMd_Medium'} />
            {isRealMoney && isRakeEnabled && <TitleValueHorizontal label={'Rake'} value={selectedRake + '%'} labelFontStyle={'TextMd_Medium'} labelColor={'gray400'} valueColor={'gray300'} valueFontStyle={'TextMd_Medium'} />}
            <HorizontalLayout margin={'0'} gap={8}>
              <TitleValueHorizontal label={'Password'} value={!/\D/g.test(password) && password ? password : '-'} labelFontStyle={'TextMd_Medium'} labelColor={'gray400'} valueColor={'gray300'} valueFontStyle={'TextMd_Medium'} />
              {/^\d{4}$/.test(password) && (
                <IconWrap onClick={handleCopy}>
                  <CopyIcon color={'gray400'} />
                </IconWrap>
              )}
            </HorizontalLayout>
          </SummaryContainer>
        )}
        <BaseButton
          size={isMobile ? 'btn40' : 'btn60'}
          text={'Create Table'}
          textStyle={isMobile ? 'TextSmall_Semibold' : 'TextLg_Semibold'}
          color={'primary'}
          onClick={() => {
            if (isCreateValid) handleCreateTable().then();
          }}
          disabled={!isCreateValid}
        />
      </LinearLayout>
    </ModalContainer>
  );
};

export default CreateTableTemplate;

const StyledTooltip = styled.div`
  position: relative;
  display: flex;
`;

const IconWrap = styled.div`
  cursor: pointer;

  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.gray300};
      }
    }
  }
`;
const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 80vh; /* 최대 높이를 설정하여 모달이 화면을 넘지 않도록 설정 */
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.error500};
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  margin-top: 4px;
`;

const SummaryContainer = styled(HorizontalLayout)`
  margin: 0;
  padding: 24px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray700};
  border-radius: 12px;

  @media (max-width: 768px) {
    min-width: 0;
    padding: 12px;
  }
`;

const Tooltip = styled.div<{ isRealMoney: boolean; isRakeEnabled: boolean }>`
  position: absolute;
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  color: ${({ theme }) => theme.colors.gray800};
  background-color: ${({ theme }) => theme.colors.gray50};
  text-align: start;
  border-radius: 8px;
  padding: 12px;
  z-index: 1001;
  bottom: 120%;
  height: fit-content;
  transform: translateX(-46%);
  box-sizing: border-box;
  width: 280px;
  @media (max-width: 768px) {
    transform: translateX(-35%);
  }
`;

const RakeTooltip = styled.div<{ isRakeEnabled: boolean }>`
  position: absolute;
  ${({ theme }) => theme.textStyles.TextSmall_Medium};
  color: ${({ theme }) => theme.colors.gray800};
  background-color: ${({ theme }) => theme.colors.gray50};
  text-align: start;
  border-radius: 8px;
  padding: 12px;
  z-index: 1001;
  height: fit-content;
  bottom: 120%;
  box-sizing: border-box;
  transform: translateX(-35%);
  width: 280px;
  @media (max-width: 768px) {
    transform: translateX(-27%);
  }
`;

const RakeOption = styled.div<{ selected: boolean }>`
  cursor: pointer;
  padding: 4px 0;
  border-radius: 8px;
  background-color: ${({ selected, theme }) => (selected ? theme.colors.primary600 : theme.colors.gray750)};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.textStyles.TextSmall_Semibold};
  user-select: none;
  flex: 1;
  text-align: center;
  min-height: 36px;
  align-content: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary600};
  }
`;
