import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { dealerBtnPositionMap, mobileDealerBtnPositionMap, mobilePositionMap, positionMap, previewButtonPositionMap } from './constant';
import { Colors } from 'src/styles/Colors';
import { Card } from 'src/store/slices/streamingTypes';
import { SeatButton } from '../../../common/icon';

const winAnimation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const Winner = styled.div`
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 2%;
    z-index: 10;
    animation: ${winAnimation} 0.8s ease-in-out 0.2s forwards;
    display: flex;
    align-content: center;
    justify-content: center;

    @media (max-width: 768px) {
        top: 23%;
    }

    svg {
        will-change: filter;
        filter: drop-shadow(0px 0px 35px #000);
        width: 100%;
        height: 100%;
        top: 2%;
        z-index: 12;
        animation: ${winAnimation} 0.8s ease-in-out 0.2s forwards;
        display: flex;
        align-content: center;
        justify-content: center;

        @media (max-width: 768px) {
            width: 80%;
            height: 80%;
            top: 30%;
        }

        svg {
            will-change: filter;
            filter: drop-shadow(0px 0px 35px #000);
            width: 100%;
            height: 100%;
        }
`;

export const UserWrap = styled.div<{ isBlur?: Boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ isBlur }) => isBlur && 'filter: brightness(70%);'}
  will-change: filter;
`;

export const EmojiWrap = styled.div`
  position: absolute;
  bottom: 0;
  width: 8em;
  height: 8em;
  z-index: 30;
  @media (max-width: 768px) {
    width: 70px;
    height: 70px;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export const SeatBtnWrap = styled.div<{ isPreset?: boolean }>`
  width: ${({ isPreset }) => (isPreset ? '60px' : '5.13em')};
  height: ${({ isPreset }) => (isPreset ? '60px' : '5.13em')};
`;

export const StyledDealerButton = styled(motion.div)<{ position: number; isPreview?: boolean }>`
  position: absolute;
  z-index: 2;
  box-shadow: -7px 4px 6px 1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -7px 4px 6px 1px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: -7px 4px 6px 1px rgba(0, 0, 0, 0.5);
  overflow: visible;
  width: ${({ isPreview }) => (isPreview ? '24px' : '1.25em')};
  height: ${({ isPreview }) => (isPreview ? '24px' : '1.25em')};
  border-radius: 50%;

  ${({ position, isPreview }) => css`
    @media (min-width: 768px) {
      ${isPreview ? previewButtonPositionMap[position] : dealerBtnPositionMap[position]}
    }
    @media (max-width: 768px) {
      ${isPreview ? previewButtonPositionMap[position] : mobileDealerBtnPositionMap[position]}
    }
  `}
  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    font-size: 8.5px;
    svg {
      width: ${({ isPreview }) => (isPreview ? '24px' : '16px')};
      height: ${({ isPreview }) => (isPreview ? '24px' : '16px')};
    }
  }
`;

export const StyledWinningRate = styled(motion.div)<{ color?: keyof typeof Colors; position: number }>`
  position: absolute;
  top: 45%;
  z-index: 11;
  background-color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.error500)};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.textStyles.TextMd_Semibold}
  font-size: 1em;
  line-height: 1.5em;
  padding: 0 0.5em;
  border-radius: 100px;
  @media (max-width: 768px) {
    top: 37%;
    font-size: 12px;
    line-height: 18px;
    padding: 0 4px;
  }
  ${({ position }) => {
    if ([1, 2, 3, 4].includes(position)) {
      return css`
        left: 85%;
      `;
    } else {
      return css`
        right: 85%;
      `;
    }
  }};
`;

export const StyledUserText = styled.div<{ color?: keyof typeof Colors; type?: 'action' | 'status' }>`
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.white)};
  ${({ theme, type }) => (type === 'action' ? theme.textStyles.TextMd_Bold : theme.textStyles.TextMd_Semibold)}
  font-size: ${({ type }) => (type === 'action' ? '1.25em' : '1.13em')};
  line-height: 100%;
  font-style: normal;
  z-index: 9;
`;

export const Avatar = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  width: 5.75em;
  height: 5.75em;
  border-radius: 50%;
  border: 3px solid transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(16, 24, 40, 1), rgba(16, 24, 40, 1));
  background-origin: border-box;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }
`;

export const Hands = styled.div<{ isFold?: boolean; cards?: Card[]; isMySeat?: boolean }>`
  position: relative;
  min-width: 8.5em;
  min-height: 9.125em;
  z-index: 4;
  top: ${({ isFold, cards, isMySeat }) => (isFold && isMySeat && cards?.[0] !== 'XX' ? '1.2em' : '0.5em')};
  opacity: ${({ isFold, cards }) => (isFold && cards?.every(card => card === 'XX') ? '0' : '1')};
  transition: opacity 0.5s ease;
`;

export const UserCard = styled.div<{ actionBg?: keyof typeof Colors }>`
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;
  justify-content: center;
  gap: 0.31em;
  bottom: 0;
  padding: 0.75em 0.31em 0.61em 0.31em;
  box-sizing: border-box;
  border-radius: 0.5em;
  width: 8.5em;
  height: 4.19em;
  //box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.15) inset;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.4);
  background: ${({ actionBg, theme }) => (actionBg ? `linear-gradient(180deg, #1D2939 0%, #29364A 33.33%, ${theme.colors[actionBg]} 100%)` : 'linear-gradient(180deg, #1D2939 0%, #29364A 50%, #1D2939 100%)')};
`;

export const ActiveOverlay = styled.div`
  position: absolute;
  width: 7.0625em;
  height: 7.5em;
  z-index: 0;
  box-shadow:
    0px 4px 4px 0px rgba(0, 0, 0, 0.4),
    2px 4px 60px 1px rgba(255, 255, 255, 0.9);
`;

export const UserName = styled.div<{ isMySeat: boolean }>`
  ${({ theme }) => theme.textStyles.TextMd_Semibold}
  font-size: 1.125em;
  color: ${({ theme, isMySeat }) => (isMySeat ? theme.colors.warning300 : theme.colors.gray300)};
  line-height: 100%;
  max-width: 100%;
  display: block;
  /* padding: 0 1.5em; */
`;

export const UserStack = styled(motion.div)`
  color: ${({ theme }) => theme.colors.primary400};
  ${({ theme }) => theme.textStyles.TextXl_Semibold}
  font-size: 1.25em;
  line-height: 100%;
`;

export const PreviewUserStack = styled.div<{ isReserved?: boolean }>`
  color: ${({ theme, isReserved }) => (isReserved ? theme.colors.gray400 : theme.colors.primary400)};
  ${({ theme }) => theme.textStyles.TextXl_Semibold}
  font-size: 1.25em;
  line-height: 100%;
  display: flex;
  align-items: center;
`;

export const Wrap = styled.div`
  position: relative;
  cursor: pointer;
  width: 8.5em;
  height: 9.13em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledSeatButton = styled(SeatButton)<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};
  height: 100%;
  width: 100%;

  &:hover {
    ${({ disabled }) => !disabled && 'filter: brightness(150%);'}
  }
`;

export const StyledSeat = styled(motion.div)`
  position: absolute;
  z-index: 1;
  width: 8.5em;
  height: 9.13em;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ layoutId }) => {
    return css`
      @media (min-width: 768px) {
        ${positionMap[Number(layoutId?.split('-')[1])]}
      }
      @media (max-width: 768px) {
        ${mobilePositionMap[Number(layoutId?.split('-')[1])]}
      }
    `;
  }}
`;

export const StyledPreviewSeat = styled.div<{ left: number; top: number }>`
  position: absolute;
  transform: translate(-50%, -50%);
  left: ${({ left }) => `${left}%`};
  top: ${({ top }) => `${top}%`};

  font-size: 9.6px;
`;

export const TimerContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0.375em;
  width: 100%;
  transform-origin: 0 0;
  overflow: hidden;
`;

export const TimerProgress = styled.div<{ width: number; animate: boolean; timerBg: keyof typeof Colors }>`
  height: 100%;
  background-color: ${({ theme, timerBg }) => theme.colors[timerBg]};
  width: ${props => props.width}%;
  transition: ${({ animate }) => (animate ? 'width 1s linear' : 'none')};
`;

export const StyledExtraCountDown = styled.div`
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  ${({ theme }) => theme.textStyles.DisplayXs_Semibold}
  color: ${({ theme }) => theme.colors.white};
  font-size: 2.25em;
`;

export const StyledFlagWrap = styled.div`
  position: absolute;
  z-index: 9;
  top: 48%;
  border-radius: 0.13em;
  overflow: hidden;
  width: 1.5em;
  height: 1.13em;

  img {
    width: 1.5em;
    height: 1.13em;
  }
`;

export const StyledEmojiWrap = styled.div`
  position: absolute;
  left: -3.44em;
  top: 5.63em;
  background-color: rgba(52, 64, 84, 0.7);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25em;
  border-radius: 50%;
`;
