import { TextStyles } from './textStyles';
import { ButtonColors, ButtonSize } from './buttonStyles';
import { css } from '@emotion/react';
import { Colors } from './Colors';

export const ShadowStyles = {
  button: css`
    box-shadow: 0px 1px 2px 0px rgba(255, 255, 255, 0.25) inset;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.25));
  `,
  card: css`
    box-shadow:
      0px 1px 2px 0px rgba(16, 24, 40, 0.4),
      0px -3px 4px 0px rgba(102, 112, 133, 0.14) inset;
  `,
  chip: css`
    filter: drop-shadow(-7px 4px 6px rgba(0, 0, 0, 0.8));
  `,
  sliderTrack: css`
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3) inset;
  `,
  lg: css`
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.3);
  `
};

const theme = {
  textStyles: TextStyles,
  colors: Colors,
  tableBg: {
    GREEN: 'radial-gradient(50% 50% at 50% 50%, #3D7C8B 0%, #051C22 100%);',
    BLUE: 'radial-gradient(50% 50% at 50% 50%, #7981B0 0%, #0F1435 100%);',
    GRAY: 'radial-gradient(50% 50% at 50% 50%, #50719D 0%, #091B2F 100%);'
  },
  ButtonColors,
  ButtonSize,
  shadow: ShadowStyles
};

export default theme;
