import { Bounce, toast } from 'react-toastify';
import { ToastFail, ToastSuccess } from '../icon';
import React from 'react';
import { InfoCircle } from '../icon/icons/Info';
import { toFixedFloor } from '../../../utils/StringUtil';

const showToast = (msg: string, icon: JSX.Element) => {
  toast(msg, {
    icon: icon,
    position: 'top-right',
    closeButton: false,
    autoClose: 1500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: 'light',
    transition: Bounce
  });
};

export const showSuccessToast = (msg: string = 'Request Success') => {
  showToast(msg, <ToastSuccess />);
};

export const showFailToast = (msg: string = 'Request Failed') => {
  showToast(msg, <ToastFail />);
};

export const showInfoToast = (msg: string = 'Request Failed') => {
  showToast(msg, <InfoCircle />);
};

export const handleGeneralEvent = (data: any, channel: BroadcastChannel) => {
  const { header, name, payload } = typeof data === 'string' ? JSON.parse(data) : data;
  if (payload && payload?.data) {
    const { data } = payload;
    const displayDecimals = data?.displayDecimals ?? 2;
    const displayAmount = toFixedFloor(Number(data?.amount ?? 0), displayDecimals);
    if (data?.type === 'DEPOSIT') {
      if (data?.status === 'COMPLETED') {
        showSuccessToast(`Deposit Completed - ${displayAmount}${data?.asset}`);
      } else if (data?.status === 'FAILED') {
        showFailToast(`Deposit Failed - ${displayAmount}${data?.asset}`);
      } else {
        showInfoToast(`Deposit Pending - ${displayAmount}${data?.asset}`);
      }
    } else if (data?.type === 'WITHDRAWAL') {
      if (data?.status === 'COMPLETED') {
        showSuccessToast(`Withdrawal Completed - ${displayAmount}${data?.asset}`);
      } else if (data?.status === 'FAILED') {
        showFailToast(`Withdrawal Failed - ${displayAmount}${data?.asset}`);
      } else {
        showInfoToast(`Withdrawal Pending - ${displayAmount}${data?.asset}`);
      }
    } else if (data?.type === 'SEND') {
      if (data?.status === 'SUCCESS') {
        showSuccessToast(`Received - ${displayAmount}${data?.asset}`);
      }
    }
    channel.postMessage({ type: 'UPDATE_ASSET' });
  }
};
