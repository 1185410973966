import React from 'react';
import styled from '@emotion/styled';
import { XCloseIcon } from '../../common/icon/icons/XCloseIcon';
import HorizontalLayout from '../../layout/HorizontalLayout';
import { dismissModal, ModalType, showModalWithRequest } from '../../../store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import Text from '../../common/text/Text';
import BaseButton from '../../common/button/BaseButton';
import useDevice from '../../../hooks/useDevice';
import { useMyBalance } from '../../../hooks/useMyBalance';
import { toFixedFloor } from '../../../utils/StringUtil';
import LinearLayout from '../../layout/LinearLayout';

const CashierTemplate = () => {
  const dispatch = useDispatch();
  const closeModal = () => dispatch(dismissModal());
  const openDepositModal = () => dispatch(showModalWithRequest({ type: ModalType.DepositModal, data: { hasBack: true } }));
  const openWithdrawModal = () => dispatch(showModalWithRequest({ type: ModalType.WithdrawModal, data: { hasBack: true } }));
  const openSendModal = () => dispatch(showModalWithRequest({ type: ModalType.SendModal, data: { hasBack: true } }));
  const { isMobile } = useDevice();
  const { myBalance } = useMyBalance();

  return (
    <ModalWrap>
      <HorizontalLayout margin={'0'}>
        <Title>Cashier</Title>
        <RightIconDiv>
          <XCloseIcon onClick={closeModal} />
        </RightIconDiv>
      </HorizontalLayout>
      <StyledBalance>
        <Text text={'Estimated Balance'} textAlign={'start'} fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textColor={'gray400'} />
        <Text text={`$${toFixedFloor(Number(myBalance?.estimateBalance ?? 0), 2) ?? ''}`} fontStyle={isMobile ? 'TextLg_Semibold' : 'DisplayXs_Semibold'} textAlign={'start'} textColor={'white'} />
      </StyledBalance>
      <StyledCurrencies>
        <StyledCurrenciesTitle>
          <Text text={'Your currencies'} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textColor={'gray400'} />
          <Text text={'Amount'} fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textColor={'gray400'} />
        </StyledCurrenciesTitle>
        <StyledCurrenciesItemList>
          {myBalance?.balances?.map((item, index) => (
            <StyledCurrencyItem key={index}>
              <img src={item.symbolImage} alt={item.assetName} />
              <StyledCurrencyContent>
                <StyledCurrencyText>
                  <Text text={item.assetName} fontStyle={'TextMd_Semibold'} textColor={'white'} />
                  <Text text={item.assetDisplayName} fontStyle={'TextSmall_Medium'} textColor={'gray400'} />
                </StyledCurrencyText>
                <StyledCurrencyText>
                  <Text text={toFixedFloor(Number(item.amount ?? 0), 2)} fontStyle={'TextMd_Semibold'} textColor={'white'} />
                  <Text text={`$${toFixedFloor(Number(item.currencyAmount ?? 0), 2)}`} fontStyle={'TextSmall_Medium'} textColor={'gray400'} />
                </StyledCurrencyText>
              </StyledCurrencyContent>
            </StyledCurrencyItem>
          ))}
        </StyledCurrenciesItemList>
      </StyledCurrencies>
      <LinearLayout gap={isMobile ? 8 : 16}>
        <StyledButtons>
          <BaseButton textStyle={isMobile ? 'TextSmall_Semibold' : 'TextLg_Semibold'} block={true} size={isMobile ? 'btn40' : 'btn60'} text={'Deposit'} color={'primary'} onClick={openDepositModal} />
        </StyledButtons>
        <StyledButtons>
          <BaseButton textStyle={isMobile ? 'TextSmall_Semibold' : 'TextLg_Semibold'} block={true} size={isMobile ? 'btn40' : 'btn60'} text={'Send'} color={'gray'} onClick={openSendModal} />
          <BaseButton textStyle={isMobile ? 'TextSmall_Semibold' : 'TextLg_Semibold'} block={true} size={isMobile ? 'btn40' : 'btn60'} text={'Withdraw'} color={'gray'} onClick={openWithdrawModal} />
        </StyledButtons>
      </LinearLayout>
    </ModalWrap>
  );
};

export default CashierTemplate;

const StyledCurrencyContent = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const StyledCurrencyText = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButtons = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 768px) {
    gap: 8px;
  }
`;

const StyledBalance = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
`;

const StyledCurrencies = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.gray750};
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 12px 16px;
  }
`;
const StyledCurrenciesTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 12px;
  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

const StyledCurrenciesItemList = styled.div``;
const StyledCurrencyItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  img {
    width: 32px;
    height: 32px;
  }
`;

const RightIconDiv = styled.div`
  display: flex;
  align-items: center;
`;

const ModalWrap = styled.div`
  gap: 24px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 32px 40px;
  border-radius: 12px;
  box-sizing: border-box;
  width: 600px;
  @media (max-width: 768px) {
    width: 100%;
    gap: 16px;
    padding: 24px 20px;
  }
`;

const Title = styled.h1`
  ${({ theme }) => theme.textStyles.TextXl_Semibold};
  color: ${({ theme }) => theme.colors.white};
  text-align: start;
  display: flex;
  flex: 1;
  margin: 0;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextMd_Semibold};
  }
`;
