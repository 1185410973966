import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import streamingSlice, { isChatEventPacket } from 'src/store/slices/streaming';
import { bpSocketService, SocketNamespace } from 'src/services/BpWebSocketService';
import { useParams } from 'react-router-dom';
import { EventName, HeaderAction, HeaderType, TableEventPacketWithDelay } from '../store/slices/streamingTypes';
import SoundManager from '../utils/SoundUtil';
import { handleGeneralEvent, showFailToast } from '../components/common/toast/BpToast';
import { ModalStatus, ModalType, showModal } from '../store/slices/modalSlice';

import { ErrorTypes } from '../store/api/api';
import _ from 'lodash';
import { useLazyGetUserProfileQuery } from '../store/api/user';
import { isDebug } from '../index';
import useTableActions from './useTableActions';
import usePageVisibility from './usePageVisibility';
import usePageMessage from './usePageMessage';
import { RootState } from '../store/store';
import { DELAY_ROUND } from '../utils/AnimationUtil';
import { useTableContext } from './TableProvider';
import { logDebug, logError } from '../utils/ConsoleUtil';
import { useAuthContext } from './AuthContext';
import { selectTableSelectedSeatId } from '../store/slices/tableSlice';

export type TableValidState = 'LOADING' | 'JOINING' | 'SUCCESS' | typeof ErrorTypes.RESTRICTED_IP | ErrorTypes.RESTRICTED_LOC | ErrorTypes.CLOSED_TABLE | ErrorTypes.NOT_FOUND_TABLE | ErrorTypes.UNAUTHORIZED | ErrorTypes.UNKNOWN;

let lastPacketTime: number | null = null;

const useTableInit = () => {
  const { isAuthorized, expiresAt } = useAuthContext();
  const { shareCode } = useParams<{ shareCode: string }>();
  const [tableLoadingState, setTableLoadingState] = useState<TableValidState>('LOADING');
  const dispatch = useDispatch();
  const [dataQueue, setDataQueue] = useState<TableEventPacketWithDelay[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isVisible = usePageVisibility();
  const { tableJoinDetails: pageMessage, resetPageMassage } = usePageMessage();
  const { tableId, headerType, action, isOwner, tableStatus, updateInfo, mySeatStatus } = useTableContext();
  const { showVerifyPasswordModal } = useTableActions();
  const [fetchMyProfile] = useLazyGetUserProfileQuery();
  const channel = new BroadcastChannel('base-channel');
  const { modalResponse } = useSelector((state: RootState) => state.modalStateSlice);
  const reservedTableSeatId = useSelector((state: RootState) => selectTableSelectedSeatId(state, tableId));

  const parsePacket = (messageData: any): any => {
    const { name, header, payload } = typeof messageData === 'string' ? JSON.parse(messageData) : messageData;
    handleHeaderActions(header, payload);
    handleErrorCodes(payload?.error?.code);
    return messageData;
  };

  const handleChatEvent = (chatData: any) => {
    dispatch(streamingSlice.actions.storeChat(chatData));
  };

  const handleTableEvent = (tableData: any) => {
    if (isDebug) logPacketDelay();
    const parsedData = parsePacket(tableData);
    const delay = calculateDelayFromNewData(parsedData);
    setDataQueue(prevQueue => [...prevQueue, { ...parsedData, delay }]);
  };

  const handleMessageEvent = (messageData: any) => {
    parsePacket(messageData);
  };

  const handleLobbyEvent = (data: any) => {
    if (isDebug) logPacketDelay();
    const parsedData = parsePacket(data);
    if (isChatEventPacket(parsedData)) {
      dispatch(streamingSlice.actions.storeChat(parsedData));
    } else {
      const delay = calculateDelayFromNewData(parsedData);
      setDataQueue(prevQueue => [...prevQueue, { ...parsedData, delay }]);
    }
  };

  const handleDisconnect = (reason: any) => {
    logError('Disconnected from socket server: ', reason);
    dispatch(showModal(ModalType.TableDisconnectedModal));
  };

  const handleHeaderActions = (header: any, payload: any) => {
    if (header?.action === HeaderAction.USER_JOIN) {
      if (header?.type === HeaderType.PRIVATE) {
        bpSocketService.chatJoin();
      }
      _.delay(() => {
        setTableLoadingState('SUCCESS');
      }, 250);
    }
    // else if (header?.action === HeaderAction.ROUND_SETTLEMENT) {
    //   const delayTime = Math.floor(Math.random() * 20 + 1) * 100;
    //   _.delay(() => bpSocketService.gameShowHand([0, 1]), delayTime);
    // }
    if (header?.action === HeaderAction.TABLE_RESERVED_CLOSE) {
      showFailToast('The table will be closed on the next hand by the table owner');
    } else if (header?.action === HeaderAction.TABLE_CLOSE) {
      dispatch(showModal(payload?.update?.subject === 'owner' ? ModalType.ClosedByOwnerModal : ModalType.ClosedByServerModal));
    } else if (header?.action === HeaderAction.UPDATE_PROFILE) {
      fetchMyProfile().unwrap().then().catch();
    }
    /**
     * 사용자 잔고 새로고침이 필요한 Table 이벤트
     */
    if (header?.action === HeaderAction.ADD_CHIPS || header?.action === HeaderAction.USER_STAND || header?.action === HeaderAction.TABLE_CLOSE) {
      channel.postMessage({ type: 'UPDATE_BALANCE' });
    }
  };

  const handleErrorCodes = (code: string | undefined) => {
    if (!code) return;
    switch (code) {
      case ErrorTypes.CLOSED_TABLE:
        setTableLoadingState(ErrorTypes.CLOSED_TABLE);
        break;
      case ErrorTypes.NOT_FOUND_TABLE:
        setTableLoadingState(ErrorTypes.NOT_FOUND_TABLE);
        break;
      case ErrorTypes.RESTRICTED_IP:
        setTableLoadingState(ErrorTypes.RESTRICTED_IP);
        break;
      case ErrorTypes.RESTRICTED_LOC:
        setTableLoadingState(ErrorTypes.RESTRICTED_LOC);
        break;
      case ErrorTypes.PASSWORD_INVALID:
      case ErrorTypes.PASSWORD_EXCEEDED:
      case ErrorTypes.LOW_AMOUNT_TO_SEAT_IN:
      case ErrorTypes.ALREADY_REQUEST:
      case ErrorTypes.INVALID_PARAMS:
      case ErrorTypes.TABLE_INVALID_SEAT:
        break;
      default:
        showFailToast(`${code}`);
        break;
    }
  };

  const processQueue = async (useLatest: boolean) => {
    if (dataQueue.length > 0 && !isProcessing) {
      setIsProcessing(true);
      if (useLatest) {
        const lastEvent = dataQueue[dataQueue.length - 1];
        dispatch(streamingSlice.actions.storeTable(lastEvent));
        setDataQueue([]);
      } else {
        const currentEvent = dataQueue[0];
        await new Promise(resolve => setTimeout(resolve, currentEvent.delay)); // Wait for the specified delay
        dispatch(streamingSlice.actions.storeTable(currentEvent)); // Dispatch the action
        setDataQueue(queue => queue.slice(1)); // Remove the processed item from the queue
      }
      setIsProcessing(false);
    }
  };

  const initializeTable = async () => {
    if (shareCode) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      if (isVisible && dataQueue.length > 0) {
        processQueue(true).then();
      }

      timeoutRef.current = setTimeout(async () => {
        await SoundManager.create();
        bpSocketService.offAll();
        bpSocketService.connect(() => {
          bpSocketService.on(SocketNamespace.DISCONNECT, handleDisconnect);
          if (isAuthorized) {
            setTableLoadingState('JOINING');
            bpSocketService.on(SocketNamespace.CHAT, handleChatEvent);
            bpSocketService.on(SocketNamespace.TABLE, handleTableEvent);
            bpSocketService.on(SocketNamespace.MESSAGE, handleMessageEvent);
            bpSocketService.tableJoin(shareCode);
          } else {
            bpSocketService.on(SocketNamespace.LOBBY, handleLobbyEvent);
            bpSocketService.lobbyJoin(undefined, shareCode);
          }
          bpSocketService.on(SocketNamespace.GENERAL, msg => {
            handleGeneralEvent(msg, channel);
          });
          bpSocketService.generalJoin();
        });
      }, 250);
    } else {
      setTableLoadingState(ErrorTypes.NOT_FOUND_TABLE);
    }

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      bpSocketService.offAll();
    };
  };

  useEffect(() => {
    /**
     * 테이블 초기화
     */
    initializeTable().then();
  }, [shareCode, expiresAt, isAuthorized, isVisible]);

  useEffect(() => {
    /**
     * 모달 응답 처리
     */
    if (modalResponse !== null && modalResponse.type === ModalType.PasswordVerifyModal && modalResponse.status === ModalStatus.Cancel) {
      logDebug('Modal Response : ', modalResponse);
      if (typeof modalResponse.data?.reconnect === 'boolean' && modalResponse.data?.reconnect === true) {
        initializeTable().then();
        return;
      }
    }
  }, [modalResponse]);

  useEffect(() => {
    /**
     * 데이터 큐에 데이터가 있고 처리 중이 아닐 때 큐 처리
     */
    if (dataQueue.length > 0 && !isProcessing) {
      processQueue(false).then();
    }
  }, [dataQueue, isProcessing]);

  useEffect(() => {
    if (action === HeaderAction.USER_JOIN && tableLoadingState === 'SUCCESS') {
      if (pageMessage?.needJoin || reservedTableSeatId !== undefined) {
        showVerifyPasswordModal(pageMessage?.seatId ?? reservedTableSeatId);
        resetPageMassage();
      }
    }
  }, [tableLoadingState, action, pageMessage, reservedTableSeatId]);

  return {
    tableLoadingState
  };
};

export default useTableInit;

// 패킷 딜레이를 계산하는 함수
function calculateDelayFromNewData(newData: any): number {
  const { name, header, payload } = newData;
  const resumeAt = payload?.update?.timerResumeAt || 250;
  const resumeAtDate = new Date(resumeAt);
  const now = new Date();
  const delay = Math.max(resumeAtDate.getTime() - now.getTime(), 250);
  // logDebug(`패킷 주입 딜레이 : ${delay} sec`);

  const roundActionDelay = [HeaderAction.ROUND_FLOP, HeaderAction.ROUND_TURN, HeaderAction.ROUND_RIVER, HeaderAction.ROUND_SETTLEMENT];
  const handActionDelay = [EventName.HAND_PROGRESS_EVENT, EventName.HAND_EVENT, EventName.HAND_ACTION];

  if (roundActionDelay.includes(header?.action)) {
    return DELAY_ROUND;
  } else if (handActionDelay.includes(name)) {
    return ![HeaderAction.TABLE_START].includes(header?.action) ? 150 : 0;
  } else {
    return 0;
  }
}

// 디버그용 패킷 딜레이 로깅 함수
function logPacketDelay() {
  const currentTime = Date.now();
  if (lastPacketTime !== null) {
    const timeDifference = (currentTime - lastPacketTime) / 1000;
    // logDebug(`패킷 간격 : ${timeDifference} sec`);
  }
  lastPacketTime = currentTime;
}
