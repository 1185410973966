import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { BalanceResponse } from '../api/responseTypes';
import { PURGE } from 'redux-persist';
import { AssetHistoryItem, WalletTransaction } from '../api/dto';
import { assetsApi } from '../api/assets';
import { createSelector } from 'reselect';

type UserAssetState = {
  userBalance: BalanceResponse | undefined;
  walletHasMore: boolean;
  historyHasMore: boolean;
  walletTransactions: WalletTransaction[];
  historyItems: AssetHistoryItem[];
};

const initialState: UserAssetState = {
  userBalance: undefined,
  walletHasMore: true,
  historyHasMore: true,
  walletTransactions: [],
  historyItems: []
};

const userAssetSlice = createSlice({
  name: 'userAssetSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(PURGE, (state, action) => {
      return initialState;
    });
    builder.addMatcher(assetsApi.endpoints.getBalance.matchFulfilled, (state, { payload }) => {
      state.userBalance = payload.data || undefined;
    });
    builder.addMatcher(assetsApi.endpoints.transactionList.matchFulfilled, (state, { payload }) => {
      const newTransactions = payload?.data.transactions || [];
      const transactionMap = new Map(state.walletTransactions.map(item => [item.id, item]));
      newTransactions.forEach(transaction => {
        transactionMap.set(transaction.id, transaction);
      });
      state.walletHasMore = newTransactions.length % 20 === 0 && newTransactions.length !== 0;
      state.walletTransactions = Array.from(transactionMap.values()).sort((a, b) => b.id - a.id);
    });
    builder.addMatcher(assetsApi.endpoints.transactionList.matchRejected, (state, { payload }) => {
      state.walletHasMore = false;
    });
    builder.addMatcher(assetsApi.endpoints.historyList.matchFulfilled, (state, { payload }) => {
      const newHistories = payload?.data.histories || [];
      const historyMap = new Map(state.historyItems.map(item => [item.id, item]));
      newHistories.forEach(history => {
        historyMap.set(history.id, history);
      });
      state.historyHasMore = newHistories.length % 20 === 0 && newHistories.length !== 0;
      state.historyItems = Array.from(historyMap.values()).sort((a, b) => b.id - a.id);
    });
    builder.addMatcher(assetsApi.endpoints.historyList.matchRejected, (state, { payload }) => {
      state.historyHasMore = false;
    });
  }
});

export default userAssetSlice;

export const selectWalletTransactions = (state: RootState) => state.userAssetSlice.walletTransactions;
export const selectWalletHasMore = (state: RootState) => state.userAssetSlice.walletHasMore;

export const selectHistoryItems = (state: RootState) => state.userAssetSlice.historyItems;
export const selectHistoryHasMore = (state: RootState) => state.userAssetSlice.historyHasMore;

export const selectTransactionsByType = createSelector([selectWalletTransactions, (_: RootState, type: 'ALL' | 'DEPOSIT' | 'WITHDRAWAL') => type], (walletTransactions, type) => {
  if (type === 'ALL') {
    return walletTransactions;
  }
  return walletTransactions.filter(transaction => transaction.type === type);
});

export const selectTransactionsCountByType = createSelector([selectTransactionsByType], transactions => transactions.length);

export const selectLastTransactionIdByType = createSelector([selectTransactionsByType], transactions => (transactions.length > 0 ? transactions[transactions.length - 1].id : undefined));

export const selectHistoryCount = createSelector([selectHistoryItems], histories => histories.length);

export const selectLastHistoryId = createSelector([selectHistoryItems], histories => (histories.length > 0 ? histories[histories.length - 1].id : undefined));

export const selectUserBalance = (state: RootState) => state.userAssetSlice.userBalance;
