import { CardProps } from './types';
import { BigSuit, CardBack, CardFront, CardNum, CardPattern, LogoWrap, SmallSuit } from './style';
import useCard from './useCard';
import useAnimationControl from '../../../hooks/useAnimation';
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { HeaderAction } from '../../../store/slices/streamingTypes';
import { motion } from 'framer-motion';
import { LogoIcon } from '../icon';
import { useTableContext } from '../../../hooks/TableProvider';

const Card = ({ cardType, card, cardIndex, patternColor = 'gray700', isFolded, ...props }: CardProps) => {
  const { bgColor, smallSuit, suit, textColor, borderColor } = useCard({ card });
  const { action } = useTableContext();

  useEffect(() => {
    if (card && cardType === 'community') {
      dealCommunityCard({
        hasAnimation: action !== HeaderAction.USER_JOIN,
        cardIndex,
        isFlipped: card !== 'XX'
      }).then();
    }
  }, [card, cardType, action]);

  useEffect(() => {
    if (cardType === 'hole' && card) {
      if (action === HeaderAction.ROUND_PREFLOP || action === HeaderAction.USER_JOIN) {
        dealHoleCard({
          hasAnimation: action === HeaderAction.ROUND_PREFLOP,
          cardIndex,
          isFlipped: card !== 'XX'
        }).then();
      } else if (card !== 'XX') {
        cardOpen({ cardIndex }).then();
      }
    }
  }, [action, card, cardType, cardIndex]);

  const { dealHoleCard, dealCommunityCard, cardOpen, reset, initial, controls, ref } = useAnimationControl({ type: 'card' });

  useEffect(() => {
    if (action === HeaderAction.TABLE_START || action === HeaderAction.TABLE_PAUSE) {
      reset();
    }
  }, [action, reset, card]);

  return (
    <StyledCardWrap cardType={cardType} cardIndex={cardIndex} ref={ref}>
      <StyledMotionDiv initial={initial} animate={controls}>
        {card && card !== 'XX' && (
          <CardFront bgColor={bgColor} borderColor={borderColor} isBestCard={props.isBestCard} disabled={props.disabled}>
            <CardNum color={textColor}>{card?.[0] === 'T' ? '10' : card?.[0]}</CardNum>
            <SmallSuit>{smallSuit}</SmallSuit>
            <BigSuit>{suit}</BigSuit>
          </CardFront>
        )}
        <CardBack>
          <CardPattern patternColor={patternColor}>
            <div />
          </CardPattern>
          <LogoWrap>
            <LogoIcon color={'gray400'} />
          </LogoWrap>
        </CardBack>
      </StyledMotionDiv>
    </StyledCardWrap>
  );
};

const StyledMotionDiv = styled(motion.div)`
  transform-style: preserve-3d;
  position: absolute;
`;

const StyledCardWrap = styled.div<{ cardIndex?: number; cardType: 'community' | 'hole' }>`
  position: absolute;
  width: 5em;
  height: 3.13em;
  left: 0;
`;

export default React.memo(Card);
