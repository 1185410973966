import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useUserProfile } from './useUserProfile';
import Intercom from '@intercom/messenger-js-sdk';

const useAnalytics = (path: string) => {
  const { user } = useUserProfile();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: path === 'holdem' ? path : location.pathname, title: location.pathname });
  }, [path]);

  useEffect(() => {
    if (user) {
      ReactGA.gtag('set', { user_id: user.id });
      ReactGA.gtag('config', user.id, {
        name: user.name,
        country: user.country,
        createdAt: user.createdAt
      });
      if (path !== 'holdem') {
        Intercom({
          app_id: 'jlnk6voo',
          user_id: user?.id.toString(),
          name: user?.name,
          email: user?.email
        });
      }
    } else {
      ReactGA.gtag('set', { user_id: null });
      if (path !== 'holdem') {
        Intercom({
          app_id: 'jlnk6voo'
        });
      }
    }
  }, [user]);
};

export default useAnalytics;
