import React, { useEffect, useRef, useState } from 'react';
import { CardsIcon, GiftsIcon, HeaderLogoIcon, LogoIcon } from '../../common/icon';
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { ModalType, showModal } from '../../../store/slices/modalSlice';
import { useUserProfile } from '../../../hooks/useUserProfile';
import useDevice from '../../../hooks/useDevice';
import MobileMenuTemplate from '../settings/MobileMenuTemplate';
import { setActiveTab, SettingPages, supportNavigation } from '../../../store/slices/authSlice';
import { Avatar, HeaderProfile, HeaderWrap, HoverContainer, MenuItem, Menus, StyledLeftHeaderContents, StyledLogo, StyledRightHeaderContents } from './styles';
import styled from '@emotion/styled';
import BaseButton from '../../common/button/BaseButton';
import { useMyBalance } from '../../../hooks/useMyBalance';
import CashierButton from '../cashier/CashierButton';
import { toFixedFloor } from '../../../utils/StringUtil';

type HeaderProps = {
  errorType?: string;
  path?: string;
};

const MainHeader: React.FC<HeaderProps> = ({ errorType, path }) => {
  const { isMobile } = useDevice();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useUserProfile();
  const [isMenuVisible, setMenuVisible] = useState(false);
  const { myBalance } = useMyBalance();
  const amount = toFixedFloor(Number(myBalance?.estimateBalance ?? 0), 2) ?? 0;

  const goHome = () => {
    if (path !== '/') {
      navigate('/');
    }
  };

  const onMenuClick = (menu: SettingPages) => {
    switch (menu) {
      case 'Profile':
      case 'Settings':
      case 'Wallet':
      case 'History':
        navigate(`/settings`);
        dispatch(setActiveTab(menu));
        break;
      case 'Lobby':
        navigate('/lobby');
        break;
      case 'Log out':
        dispatch(showModal(ModalType.LogoutModal));
        break;
    }
    setMenuVisible(false);
  };

  const onCreateTableClick = () => {
    dispatch(showModal(ModalType.CreateTableModal));
  };

  const showAuthModal = () => dispatch(showModal(ModalType.AuthModal));

  /**
   * 마우스 클릭 시 열린 헤더를 닫는 부분
   */
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuVisible(false);
      }
    };
    if (isMobile) {
      document.removeEventListener('mousedown', handleClickOutside);
    } else {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const goLobby = () => {
    navigate('/lobby');
  };

  return (
    <HeaderWrap>
      {isMobile && isMenuVisible && <MobileMenuTemplate setMenuVisible={setMenuVisible} onMenuClick={onMenuClick} onCreateTableClick={onCreateTableClick} />}
      <StyledLeftHeaderContents>
        <StyledLogo onClick={goHome}>{isMobile && user ? <LogoIcon h={20} w={30} color={'primary500'} /> : <HeaderLogoIcon />}</StyledLogo>
        {!isMobile && (
          <StyledLinks>
            <StyledLink onClick={goLobby} isActive={path === 'lobby'}>
              <CardsIcon color={path === 'lobby' ? 'white' : 'gray400'} />
              <StyledText>Cash game</StyledText>
            </StyledLink>
            {/*<StyledLink>*/}
            {/*  <GiftsIcon color={'gray400'} />*/}
            {/*  <StyledText>Promotions</StyledText>*/}
            {/*</StyledLink>*/}
          </StyledLinks>
        )}
      </StyledLeftHeaderContents>
      {user && isMobile && amount && <CashierButton amount={amount} />}
      <StyledRightHeaderContents>
        {user ? (
          <>
            {!isMobile && amount && <CashierButton amount={amount} />}
            <HeaderProfile>
              <HoverContainer onClick={() => setMenuVisible(!isMenuVisible)}>
                <Avatar src={user?.profile} alt="Profile" />
                {isMenuVisible && (
                  <Menus isVisible={isMenuVisible} ref={menuRef}>
                    {supportNavigation
                      .filter(page => (isMobile ? true : page !== 'Lobby'))
                      .map(item => (
                        <MenuItem key={item} onClick={() => onMenuClick(item)}>
                          {item}
                        </MenuItem>
                      ))}
                  </Menus>
                )}
              </HoverContainer>
            </HeaderProfile>
          </>
        ) : (
          <StyledMainStartButton>
            <BaseButton color="primary" textColor="white" textStyle={isMobile ? 'TextSmall_Semibold' : 'TextMd_Semibold'} size={isMobile ? 'btn36' : 'btn44'} w={isMobile ? 101 : 121} onClick={showAuthModal} text="Get Started" />
          </StyledMainStartButton>
        )}
      </StyledRightHeaderContents>
    </HeaderWrap>
  );
};

const StyledMainStartButton = styled.div`
  display: flex;
  gap: 24px;
  justify-content: end;
  align-items: center;

  @media (max-width: 768px) {
    position: absolute;
    right: 0;
    padding: 20px;
  }
`;

const StyledLinks = styled.div`
  display: flex;
  gap: 32px;
`;
const StyledLink = styled.div<{ isActive?: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.gray400)};

  &:hover {
    filter: brightness(1.3);
  }

  transition: filter 0.2s;
`;
const StyledText = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Semibold};
`;

export default MainHeader;
