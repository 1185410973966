import React, { useCallback, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { ArrowLeft, CloseIcon } from '../../common/icon';
import HorizontalLayout from '../../layout/HorizontalLayout';
import { dismissModal, ModalType, showModal } from '../../../store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import useDevice from '../../../hooks/useDevice';
import LinearLayout from '../../layout/LinearLayout';
import NetworkSelect, { NetworkOptionType } from '../../common/select/NetworkSelect';
import Text from '../../common/text/Text';
import TextInput from '../../common/input/TextInput';
import WithdrawInput from './WithdrawInput';
import BaseButton from '../../common/button/BaseButton';
import { useMyBalance } from '../../../hooks/useMyBalance';
import { AssetType } from '../../../store/api/responseTypes';
import { useLazyCheckVerifyAddressQuery, useRequestWithdrawalMutation } from '../../../store/api/assets';
import useNetworkInfo from '../../../hooks/useNetworkInfo';
import CurrencyView from './CurrencyView';
import { toFixedFloor } from '../../../utils/StringUtil';
import { showFailToast } from '../../common/toast/BpToast';
import { getErrorMessageByCode } from '../../../store/api/api';
import { debounce } from 'lodash';
import { addBigNumbers, subtractBigNumbers } from '../../../utils/BigNumberUtil';

const WithdrawModal = ({ hasBack = true }: { hasBack?: boolean }) => {
  const [walletAddress, setWalletAddress] = React.useState('');
  const [walletAddressValid, setWalletAddressValid] = React.useState(false);
  const [amount, setAmount] = React.useState('');
  const [selectedTokenBalance, setSelectedTokenBalance] = React.useState('0.00');
  const [selectedNetwork, setSelectedNetwork] = React.useState<NetworkOptionType | null>(null);
  const dispatch = useDispatch();
  const closeModal = () => dispatch(dismissModal());
  const { isMobile } = useDevice();
  const backToCashierModal = () => dispatch(showModal(ModalType.CashierModal));
  const { tokenInfos } = useNetworkInfo(AssetType.USDT);
  const { myBalance: data } = useMyBalance();
  const myBalance = Number(data?.estimateBalance ?? 0);
  const networkWithdrawConfig = useMemo(() => tokenInfos?.find(info => info.chainNetworkName === selectedNetwork?.type), [selectedNetwork, tokenInfos]);
  const withdrawDecimal = useMemo(() => networkWithdrawConfig?.displayDecimals ?? 2, [networkWithdrawConfig]);
  const withdrawFeeAmount = useMemo(() => toFixedFloor(networkWithdrawConfig?.withdrawFeeAmount ?? 0, withdrawDecimal), [networkWithdrawConfig]);
  const minWithdrawAmount = useMemo(() => toFixedFloor(networkWithdrawConfig?.minWithdrawAmount ?? 0, withdrawDecimal), [networkWithdrawConfig]);
  const [checkValidAddress, { isSuccess }] = useLazyCheckVerifyAddressQuery();
  const [amountErrorMessage, setAmountErrorMessage] = React.useState<string>('');

  const [mutate, { isLoading }] = useRequestWithdrawalMutation();

  const checkAddress = useCallback(
    debounce((chainNetwork: string, address: string) => {
      checkValidAddress({ chainNetwork, address })
        .unwrap()
        .then(response => {
          setWalletAddressValid(response?.data);
        })
        .catch(() => {
          setWalletAddressValid(false);
        });
    }, 250),
    []
  );

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedNetwork !== null) {
      checkAddress(selectedNetwork.type, e.target.value);
    }
    setWalletAddress(e.target.value);
  };

  useEffect(() => {
    if (myBalance < Number(minWithdrawAmount)) {
      return setAmountErrorMessage('Not enough balance');
    } else if (Number(amount) > 0 && subtractBigNumbers(Number(amount), Number(withdrawFeeAmount)).toNumber() < Number(minWithdrawAmount)) {
      return setAmountErrorMessage(`Minimum withdrawal amount is ${toFixedFloor(addBigNumbers(Number(minWithdrawAmount ?? 0), Number(withdrawFeeAmount)).toNumber(), withdrawDecimal) ?? '0.00'} USDT`);
    }
    setAmountErrorMessage('');
  }, [myBalance, minWithdrawAmount, amount]);

  const handleAmountChange = (amount: string) => {
    setAmount(amount);
  };

  useEffect(() => {
    setSelectedTokenBalance(toFixedFloor(Number(data?.balances?.find(item => item.assetName === 'USDT')?.amount ?? 0), withdrawDecimal) ?? '0.00');
  }, [data]);

  const withdraw = async () => {
    if (!walletAddress || subtractBigNumbers(Number(amount), Number(withdrawFeeAmount)).toNumber() < Number(minWithdrawAmount) || !selectedNetwork || !isSuccess || !walletAddressValid) return;
    await mutate({ asset: AssetType.USDT, amount: Number(amount), address: walletAddress, chainNetwork: selectedNetwork.type })
      .unwrap()
      .then(() => {
        closeModal();
      })
      .catch(error => {
        showFailToast(getErrorMessageByCode(error?.code));
      });
  };

  return (
    <ModalWrap>
      <HorizontalLayout margin={'0'}>
        {hasBack && (
          <LeftIconDiv>
            <ArrowLeft onClick={backToCashierModal} />
          </LeftIconDiv>
        )}
        <Title hasBack={hasBack}>{'Withdraw'}</Title>
        <RightIconDiv>
          <CloseIcon onClick={closeModal} />
        </RightIconDiv>
      </HorizontalLayout>
      <LinearLayout gap={16}>
        <CurrencyView balances={data?.balances ?? []} />
        <LinearLayout gap={isMobile ? 2 : 4}>
          <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} textAlign={'start'} textColor={'gray300'} text={'Network'} />
          <NetworkSelect assetType={AssetType.USDT} onChange={network => setSelectedNetwork(network)} />
        </LinearLayout>
        <LinearLayout gap={isMobile ? 2 : 4}>
          <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} textAlign={'start'} textColor={'gray300'} text={'Your USDT address'} />
          <TextInput type={'text'} placeholder={'Enter your USDT address'} onChange={handleAddressChange} />
          {walletAddress && !walletAddressValid && <Text textAlign={'start'} text={'Please enter a valid USDT - ERC-20 address'} fontStyle={'TextSmall_Medium'} textColor={'error500'} />}
        </LinearLayout>
        <LinearLayout gap={isMobile ? 2 : 4}>
          <HorizontalLayout justifyContent={'space-between'} margin={'0'}>
            <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextMd_Medium'} textAlign={'start'} textColor={'gray300'} text={'Amount'} />
            <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textColor={'white'} text={`${selectedTokenBalance} USDT`} />
          </HorizontalLayout>
          <WithdrawInput value={amount} placeholder={'0.00 USDT'} max={selectedTokenBalance} suffix={'Max'} onValueChange={handleAmountChange} />
          {amountErrorMessage && <Text textAlign={'start'} text={amountErrorMessage} fontStyle={'TextSmall_Medium'} textColor={'error500'} />}
        </LinearLayout>
        <LinearLayout gap={0}>
          <HorizontalLayout margin={'0'}>
            <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'gray400'} text={'Receive amount'} />
            <Text
              fontStyle={isMobile ? 'TextMd_Semibold' : 'TextXl_Semibold'}
              textColor={'white'}
              text={`${subtractBigNumbers(Number(amount), Number(withdrawFeeAmount)).toNumber() > 0 ? toFixedFloor(subtractBigNumbers(Number(amount), Number(withdrawFeeAmount)).toNumber(), withdrawDecimal) : toFixedFloor(0, withdrawDecimal)} USDT`}
            />
          </HorizontalLayout>
          <HorizontalLayout margin={'0'}>
            <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={'start'} textColor={'gray400'} text={'Network fee'} />
            <Text fontStyle={isMobile ? 'TextSmall_Medium' : 'TextMd_Medium'} textColor={'gray400'} text={`${toFixedFloor(Number(withdrawFeeAmount), withdrawDecimal)} USDT`} />
          </HorizontalLayout>
        </LinearLayout>
      </LinearLayout>
      <BaseButton
        isLoading={isLoading}
        size={isMobile ? 'btn40' : 'btn60'}
        text={'Withdraw'}
        color={'primary'}
        textStyle={isMobile ? 'TextSmall_Semibold' : 'TextLg_Semibold'}
        disabled={!walletAddress || subtractBigNumbers(Number(amount), Number(withdrawFeeAmount)).toNumber() < Number(minWithdrawAmount) || !selectedNetwork || !isSuccess || !walletAddressValid}
        onClick={withdraw}
      />
    </ModalWrap>
  );
};

export default WithdrawModal;

const LeftIconDiv = styled.div`
  display: flex;
  align-items: center;
`;

const RightIconDiv = styled.div`
  display: flex;
  align-items: center;
`;

const ModalWrap = styled.div`
  width: 100%;
  gap: 24px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray800};
  padding: 32px 40px;
  border-radius: 12px;
  min-width: 600px;
  max-width: 600px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    min-width: 0;
    gap: 16px;
    padding: 24px 20px;
  }
`;
const Title = styled.h1<{ hasBack: boolean }>`
  ${({ theme }) => theme.textStyles.TextXl_Semibold};
  color: ${({ theme }) => theme.colors.white};
  text-align: start;
  display: flex;
  flex: 1;
  margin: ${({ hasBack }) => (hasBack ? '0 24px' : '0 24px 0 0')};
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextMd_Semibold};
    margin: 0 8px;
  }
`;
