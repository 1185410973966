import { Navigate, RouteObject, createBrowserRouter } from 'react-router-dom';

import DefaultLayout from 'src/components/layout/DefaultLayout';
import LandingScreen from 'src/pages/LandingScreen';
import TableScreen from 'src/pages/TableScreen';
import SettingScreen from '../pages/SettingsScreen';
import AuthScreen from '../pages/AuthScreen';
import ErrorTemplate from '../components/template/utils/ErrorTemplate';
import { ErrorTypes } from '../store/api/api';
import LobbyScreen from '../pages/LobbyScreen';
import PolicyScreen from '../pages/PolicyScreen';

const routers: RouteObject[] = [
  {
    element: <DefaultLayout />,
    children: [
      {
        path: '/',
        element: <LandingScreen />,
        errorElement: <ErrorTemplate errorType={ErrorTypes.UNKNOWN} />
      },
      {
        path: 'settings',
        element: <SettingScreen />
      },
      {
        path: 'lobby',
        element: <LobbyScreen />
      },
      {
        path: 'policy',
        element: <PolicyScreen />
      },
      {
        path: 'holdem/:shareCode',
        element: <TableScreen />,
        errorElement: <ErrorTemplate errorType={ErrorTypes.UNKNOWN} />
      },
      {
        path: 'auth/:result',
        element: <AuthScreen />,
        errorElement: <ErrorTemplate errorType={ErrorTypes.UNKNOWN} />
      },
      {
        path: '*',
        element: <Navigate to="/" />,
        errorElement: <ErrorTemplate errorType={ErrorTypes.UNKNOWN} />
      }
    ]
  }
];

export default createBrowserRouter(routers);
