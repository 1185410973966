import React, { useEffect, useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import PreviewSeat from '../room/seat/PreviewSeat';
import streamingSlice from '../../../store/slices/streaming';
import { bpSocketService, SocketNamespace } from '../../../services/BpWebSocketService';
import { TableEventPacketWithDelay } from '../../../store/slices/streamingTypes';
import { ModalType, showModal } from '../../../store/slices/modalSlice';

import useTable from '../../../hooks/useTable';
import useDevice from '../../../hooks/useDevice';
import usePageVisibility from '../../../hooks/usePageVisibility';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { useCountryList } from '../../../hooks/useCountryList';
import { useAuthContext } from '../../../hooks/AuthContext';

import Text from '../../common/text/Text';
import BaseButton from '../../common/button/BaseButton';

interface TablePreviewProps {
  tableId: number;
}

const TablePreview: React.FC<TablePreviewProps> = ({ tableId }) => {
  const { isAuthorized } = useAuthContext();
  const isPageVisible = usePageVisibility();
  const { user } = useUserProfile();
  const countries = useCountryList();
  const { tableSnapshot, tableSettings, mySeatId, shareCode } = useTable(tableId);
  const { isMobile, isTablet } = useDevice();
  const [isLoading, setLoading] = useState(true);
  const [dataQueue, setDataQueue] = useState<TableEventPacketWithDelay[]>([]);
  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch();

  const calculateUserPositionsAsPercent = useCallback((numUsers: number, type: 'horizontal' | 'vertical' = 'horizontal') => {
    const positions = [];
    const step = (2 * Math.PI) / numUsers;
    for (let i = 0; i < numUsers; i++) {
      const theta = step * i + (type === 'vertical' ? Math.PI / 2 : 0);
      const xPercent = 50 + 50 * Math.cos(theta);
      const yPercent = 50 + 50 * Math.sin(theta);
      const x = type === 'vertical' ? (xPercent > 51 ? xPercent + 7 : xPercent < 49 ? xPercent - 7 : xPercent) : xPercent;
      const yAdjust = type === 'vertical' ? 1 : 1.5;
      positions.push({ x: Math.min(Math.max(0, x), 100), y: yPercent * yAdjust });
    }
    return positions;
  }, []);

  const positions = calculateUserPositionsAsPercent(9, 'vertical');

  const handleLobbyEvent = useCallback((tableData: any) => {
    const packetWithDelay: TableEventPacketWithDelay = { ...tableData };
    setDataQueue(prevQueue => [...prevQueue, packetWithDelay]);
    setLoading(false);
  }, []);

  const showAuthModal = useCallback(() => {
    dispatch(showModal(ModalType.AuthModal));
  }, [dispatch]);

  const showCreateTableModal = useCallback(() => {
    dispatch(showModal(ModalType.CreateTableModal));
  }, [dispatch]);

  const onCreateTableClick = useCallback(() => {
    isAuthorized ? showCreateTableModal() : showAuthModal();
  }, [isAuthorized, showAuthModal, showCreateTableModal]);

  const navigateTableWithSeatId = useCallback(
    (seatId?: number) => {
      if (tableId < 0 || mySeatId !== undefined) return;
      const newTab = openTable();
      if (!newTab) return;
      newTab.onload = () => {
        newTab.postMessage({ type: 'joinTable', seatId });
      };
    },
    [tableId, mySeatId, shareCode]
  );

  const openTable = useCallback(() => {
    const width = 1600;
    const height = 900;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;
    const url = `/holdem/${shareCode}`;
    const windowName = `${shareCode}`;
    const windowFeatures = `width=${width},height=${height},top=${top},left=${left}`;
    return window.open(url, windowName, windowFeatures);
  }, [shareCode]);

  const processQueue = useCallback(
    async (useLatest: boolean) => {
      if (dataQueue.length > 0 && !processing) {
        setProcessing(true);
        if (useLatest) {
          const lastEvent = dataQueue[dataQueue.length - 1];
          dispatch(streamingSlice.actions.storeTable(lastEvent));
          setDataQueue([]);
        } else {
          const currentEvent = dataQueue[0];
          dispatch(streamingSlice.actions.storeTable(currentEvent));
          setDataQueue(queue => queue.slice(1));
        }
        setProcessing(false);
      }
    },
    [dataQueue, processing, dispatch]
  );

  useEffect(() => {
    if (dataQueue.length > 0 && !processing) {
      processQueue(false).then();
    }
  }, [dataQueue, processing, processQueue]);

  useEffect(() => {
    if (tableId < 0) {
      setLoading(false);
      return;
    }

    bpSocketService.off(SocketNamespace.LOBBY);
    bpSocketService.connect(() => {
      bpSocketService.on(SocketNamespace.LOBBY, handleLobbyEvent);
      bpSocketService.lobbyJoin(tableId);
    });
    return () => {
      bpSocketService.off(SocketNamespace.LOBBY);
    };
  }, [tableId, isPageVisible, handleLobbyEvent]);

  return (
    <StyledWrap>
      {<StyledTitle>{!isMobile && !isTablet ? tableSettings?.name ?? 'Table Info' : ''}</StyledTitle>}
      <StyledTable>
        {tableId && positions.map((pos, i) => <PreviewSeat key={i} tableId={tableId} isAlreadySeated={mySeatId !== undefined} seatId={i} pos={pos} onClick={() => navigateTableWithSeatId(i)} tableSnapshot={tableSnapshot} user={user} countries={countries} />)}
        {tableSettings && (
          <StyledText
            fontStyle={'TextSmall_Medium'}
            textColor={'white'}
            text={`Buy-in Range\\n${tableSettings?.asset?.type === 'CRYPTO' ? '' : 'P'}${tableSettings.buyIn.min.toLocaleString()} - ${tableSettings?.asset?.type === 'CRYPTO' ? '' : 'P'}${tableSettings.buyIn.max.toLocaleString()} ${
              tableSettings?.asset?.type === 'CRYPTO' ? tableSettings?.asset.name : ''
            }`}
          />
        )}
      </StyledTable>
      <StyledButtonWrap>
        {isLoading && <BaseButton textStyle="TextMd_Semibold" size="btn44" bgColor="gray800" textColor="white" onClick={() => {}} />}
        {!isLoading && shareCode && (
          <BaseButton textStyle="TextMd_Semibold" size="btn44" color="gray" textColor="white" onClick={openTable}>
            OPEN
          </BaseButton>
        )}
        {!isLoading && shareCode && mySeatId === undefined && (
          <BaseButton textStyle="TextMd_Semibold" size="btn44" color="primary" textColor="white" onClick={() => navigateTableWithSeatId()}>
            JOIN
          </BaseButton>
        )}
        {!isLoading && !shareCode && (
          <BaseButton textStyle="TextMd_Semibold" size="btn44" color="primary" textColor="white" onClick={onCreateTableClick}>
            Create Table
          </BaseButton>
        )}
      </StyledButtonWrap>
    </StyledWrap>
  );
};

const StyledText = styled(Text)`
  width: 100%;
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 20px;
`;

const StyledButtonWrap = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

const StyledTitle = styled.div`
  ${({ theme }) => theme.textStyles.TextMd_Semibold}
  color: ${({ theme }) => theme.colors.white};
`;

const StyledWrap = styled.div`
  display: flex;
  border-radius: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 618px;
  padding: 24px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray800};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  @media (max-width: 992px) {
    padding: 0;
    height: auto;
    gap: 0;
  }
`;

const StyledTable = styled.div`
  position: relative;
  width: 236px;
  height: 377px;
  border-radius: 1000px;
  border: 2px solid ${({ theme }) => theme.colors.gray500};
  background: linear-gradient(180deg, #1d2939 0%, #29364a 52.08%, #1d2939 100%);
  @media (max-width: 992px) {
    width: 80%;
    aspect-ratio: 236/377;
    height: auto;
    margin-top: 10%;
    margin-bottom: 20%;
  }
`;

export default TablePreview;
