import React, { useEffect, useMemo, useState } from 'react';
import Modal, { Styles } from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import AuthTemplate from '../auth/AuthTemplate';
import CashierTemplate from '../cashier/CashierTemplate';
import { RootState } from '../../../store/store';
import { dismissModal, ModalType } from '../../../store/slices/modalSlice';
import CodeVerificationTemplate from '../auth/CodeVerificationTemplate';
import useDevice from '../../../hooks/useDevice';
import AlertModal from './AlertModal';
import BuyInModal from './BuyInModal';
import ChatModal from './ChatModal';
import SeedModal from './SeedModal';
import TableModal from './TableModal';
import { setEmail } from '../../../store/slices/authSlice';
import PasswordVerifyModal from './PasswordVeififyModal';
import TablePreviewModal from './TablePreviewModal';
import TableDisconnectedModal from './TableDisconnectedModal';
import ConnectingWalletModal from '../auth/ConnectingWalletModal';
import DepositModal from '../cashier/DepositModal';
import WithdrawModal from '../cashier/WithdrawModal';
import SendModal from '../cashier/SendModal';

interface ModalProps {
  onRequestClose?: () => void;
}

const BasePokerModal: React.FC<ModalProps> = () => {
  const { isMobile, screenSize } = useDevice();
  const dispatch = useDispatch();
  const modalType = useSelector((state: RootState) => state.modalStateSlice.currentModal);
  const modalRequest = useSelector((state: RootState) => state.modalStateSlice.modalRequest);
  const [isClosing, setIsClosing] = useState(false);
  const customModalStyles: Styles = useMemo(() => {
    let baseStyles: Styles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0 16px 32px rgba(0, 0, 0, 0.2)',
        border: 'none',
        backgroundColor: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '24px',
        padding: '0px',
        zIndex: 1000
      },
      overlay: {
        backgroundColor: '#0A0E16CC',
        overscrollBehavior: 'none',
        scrollBehavior: 'unset',
        zIndex: 999
      }
    };

    // 모달 타입에 따라 스타일을 조정합니다.
    switch (modalType) {
      case ModalType.ChatModal:
        baseStyles.content!.width = '100%';
        baseStyles.content!.height = '100%';
        baseStyles.content!.top = '0%';
        baseStyles.content!.left = '0%';
        baseStyles.content!.bottom = 'auto';
        baseStyles.content!.right = 'auto';
        baseStyles.content!.transform = 'none';
        baseStyles.content!.overflow = 'auto';
        baseStyles.overlay!.backgroundColor = 'transparent';
        break;
      case ModalType.SeedModal:
        baseStyles.content!.width = '100%';
        baseStyles.content!.height = 'auto';
        baseStyles.content!.top = 'auto';
        baseStyles.content!.left = '50%';
        baseStyles.content!.bottom = '0%';
        baseStyles.content!.right = 'auto';
        baseStyles.content!.transform = 'translateX(-50%)';
        baseStyles.content!.overflow = 'clip';
        baseStyles.overlay!.backgroundColor = 'transparent';
        break;
      default:
        baseStyles.content!.width = isMobile ? `${screenSize.width - 40}px` : 'auto';
        baseStyles.content!.borderRadius = '12px';
    }

    return baseStyles;
  }, [modalType, isMobile, screenSize.width, isClosing]);

  const getModalView = () => {
    switch (modalType) {
      case ModalType.AuthModal:
        return <AuthTemplate />;
      case ModalType.CodeVerificationModal:
        return <CodeVerificationTemplate />;
      case ModalType.CashierModal:
        return <CashierTemplate />;
      case ModalType.ProfileModal:
        return <AuthTemplate />;
      case ModalType.BuyInModal:
        return <BuyInModal />;
      case ModalType.LogoutModal:
      case ModalType.StandModal:
      case ModalType.CloseModal:
      case ModalType.ClosedByOwnerModal:
      case ModalType.ClosedByServerModal:
      case ModalType.LeaveModal:
        return <AlertModal modalType={modalType} />;
      case ModalType.ChatModal:
        return <ChatModal />;
      case ModalType.SeedModal:
        return <SeedModal />;
      case ModalType.CreateTableModal:
        return <TableModal />;
      case ModalType.PasswordVerifyModal:
        return <PasswordVerifyModal />;
      case ModalType.TablePreviewModal:
        return <TablePreviewModal tableId={modalRequest?.data?.tableId} />;
      case ModalType.TableDisconnectedModal:
        return <TableDisconnectedModal />;
      case ModalType.ConnectingWalletModal:
        return <ConnectingWalletModal />;
      case ModalType.DepositModal:
        return <DepositModal hasBack={modalRequest?.data?.hasBack} />;
      case ModalType.WithdrawModal:
        return <WithdrawModal hasBack={modalRequest?.data?.hasBack} />;
      case ModalType.SendModal:
        return <SendModal hasBack={modalRequest?.data?.hasBack} />;
    }
  };

  const modalView = useMemo(() => {
    return getModalView();
  }, [modalType]);

  useEffect(() => {
    if (modalType !== ModalType.CodeVerificationModal) {
      dispatch(setEmail(null));
    }
  }, [modalType]);

  const handleClose = () => {
    // setIsClosing(true);
    // setTimeout(() => {
    //   dispatch(dismissModal());
    //   setIsClosing(false);
    // }, 500); // Match the duration of the fade-out animation
    dispatch(dismissModal());
  };

  useEffect(() => {
    if (modalType !== null) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [modalType]);

  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      if (modalType !== null) {
        handleClose();
        event.preventDefault();
        window.history.pushState(null, '', window.location.pathname); // Prevent default back behavior
      }
    };
    window.addEventListener('popstate', handleBackButton);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [modalType]);

  /**
   * https://reactcommunity.org/react-modal/styles/transitions/
   */
  return (
    <Modal
      isOpen={modalType !== null}
      closeTimeoutMS={undefined}
      onRequestClose={handleClose}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      shouldFocusAfterRender={true}
      shouldReturnFocusAfterClose={true}
      style={customModalStyles}
      contentLabel={modalType !== null ? modalType.toString() : ''}
    >
      {modalView}
    </Modal>
  );
};

export default BasePokerModal;
