import React, { useEffect, useState } from 'react';
import SettingTab from '../components/template/settings/SettingTab';
import SettingLayout from '../components/layout/SettingLayout';
import WalletTemplate from '../components/template/settings/WalletTemplate';
import GameSettingTemplate from '../components/template/settings/GameSettingTemplate';
import ProfileTemplate from '../components/template/settings/ProfileTemplate';
import styled from '@emotion/styled';
import HistoryTemplate from '../components/template/settings/HistoryTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { selectLastPage, setActiveTab, SettingPages } from '../store/slices/authSlice';
import { ModalType, showModal } from '../store/slices/modalSlice';
import Footer from '../components/template/footer/Footer';
import { useUserProfile } from '../hooks/useUserProfile';

const SettingsScreen = () => {
  const { user } = useUserProfile();
  const dispatch = useDispatch();
  // 요청 받은 기능
  const activeTab = useSelector(selectLastPage);
  // UI
  const [activeTitle, setActiveTitle] = useState<string>('Profile');
  const [activeNode, setActiveNode] = useState<React.ReactNode>(<ProfileTemplate />);

  const tabClick = (tabName: SettingPages) => {
    dispatch(setActiveTab(tabName));
    selectTab(tabName);
    if (tabName === 'Log out') {
      dispatch(showModal(ModalType.LogoutModal));
    }
  };

  useEffect(() => {
    dispatch(setActiveTab(activeTab));
    if (activeTab !== 'Log out') {
      selectTab(activeTab);
    }
  }, [activeTab]);

  const selectTab = (activeTab: SettingPages) => {
    switch (activeTab) {
      case 'Profile':
        setActiveNode(<ProfileTemplate />);
        setActiveTitle(activeTab);
        break;
      case 'Settings':
        setActiveNode(<GameSettingTemplate />);
        setActiveTitle(activeTab);
        break;
      case 'Wallet':
        setActiveNode(<WalletTemplate />);
        setActiveTitle(activeTab);
        break;
      case 'History':
        setActiveNode(<HistoryTemplate />);
        setActiveTitle(activeTab);
        break;
      case 'Log out':
        break;
    }
  };

  return (
    <Wrap>
      {user ? (
        <>
          <SettingTab onTabClick={tabClick} activeTab={activeTitle} />
          <SettingLayout title={activeTitle}>{activeNode}</SettingLayout>
        </>
      ) : (
        <></>
      )}
      <Footer />
    </Wrap>
  );
};

const Wrap = styled.div`
  overflow: scroll;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray900};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  gap: 8px;
  padding-bottom: 366px;
  @media (max-width: 992px) {
    padding-bottom: 490px;
  }
  @media (max-width: 768px) {
    padding: 20px 20px 458px 20px;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;

export default SettingsScreen;
