import { useGetAssetConfigQuery } from '../store/api/assets';
import { AssetType } from '../store/api/responseTypes';

const useNetworkInfo = (assetType: AssetType) => {
  const { data: assetConfig } = useGetAssetConfigQuery();

  const asset = assetConfig?.data.assets.find(asset => asset.name === assetType) ?? undefined;

  if (asset) {
    return {
      network: asset,
      tokenInfos: asset.cryptoInfos
    };
  } else {
    return {
      network: undefined,
      tokenInfos: undefined
    };
  }
};

export default useNetworkInfo;
