import { useEffect } from 'react';
import { useAuthContext } from './AuthContext';
import usePageVisibility from './usePageVisibility';
import { bpSocketService, SocketNamespace } from '../services/BpWebSocketService';
import { handleGeneralEvent } from '../components/common/toast/BpToast';

const useGeneralSocket = (path: string) => {
  const { isAuthorized, channel } = useAuthContext();
  const isPageVisible = usePageVisibility();

  useEffect(() => {
    if (path !== 'holdem') {
      bpSocketService.off(SocketNamespace.GENERAL);
      if (isAuthorized && isPageVisible && channel.name) {
        bpSocketService.connect(() => {
          bpSocketService.on(SocketNamespace.GENERAL, msg => {
            handleGeneralEvent(msg, channel);
          });
          bpSocketService.generalJoin();
        });
      }
    }
    return () => {
      if (path !== 'holdem') {
        bpSocketService.off(SocketNamespace.GENERAL);
      }
    };
  }, [isAuthorized, isPageVisible, channel.name]);
};

export default useGeneralSocket;
