import styled from '@emotion/styled';
import Text from 'src/components/common/text/Text';
import React from 'react';
import { useTablePacket } from '../../../../hooks/useTableInfo';
import { numberToDisplayString } from '../../../../utils/StringUtil';
import useDevice from '../../../../hooks/useDevice';
import { useTableContext } from '../../../../hooks/TableProvider';
import { TableAssetInfo } from '../../../../store/slices/streamingTypes';
import { useCreateConfig } from '../../../../hooks/useCreateConfig';

const TableInfo = () => {
  const { handId, tableSettings, tableAssetInfo } = useTableContext();
  const { getAssetInfo } = useCreateConfig();
  const tableAssetConfig = getAssetInfo(tableAssetInfo?.name);
  const { isMobile } = useDevice();

  const getChipTitle = (assetInfo: TableAssetInfo) => {
    switch (assetInfo.name) {
      case 'BPP':
        return 'Play Money';
      default:
        return assetInfo.name;
    }
  };

  return (
    <TableInfoWrap>
      {tableSettings?.asset && <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={isMobile ? 'center' : 'start'} text={`Chip: ${getChipTitle(tableSettings?.asset)}`} textColor={isMobile ? 'white_40' : 'gray300'} marginTop={0} />}
      <Text
        fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'}
        textAlign={isMobile ? 'center' : 'start'}
        text={`Blinds: ${numberToDisplayString(tableSettings?.blindAmount?.small)} / ${numberToDisplayString(tableSettings?.blindAmount?.big)}`}
        textColor={isMobile ? 'white_40' : 'gray300'}
        marginTop={0}
      />
      {handId && handId > 0 && <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={isMobile ? 'center' : 'start'} text={`Hand: #${handId}`} textColor={isMobile ? 'white_40' : 'gray300'} marginTop={0} />}
      {tableSettings?.tablePassword && <Text fontStyle={isMobile ? 'TextXs_Medium' : 'TextSmall_Medium'} textAlign={isMobile ? 'center' : 'start'} text={`Password: ${tableSettings?.tablePassword}`} textColor={isMobile ? 'white_40' : 'gray300'} marginTop={0} />}
    </TableInfoWrap>
  );
};

const TableInfoWrap = styled.div`
  position: absolute;
  top: 76px;
  gap: 8px;
  left: 24px;
  display: flex;
  flex-direction: column;
  z-index: auto;

  @media (max-width: 768px) {
    position: absolute;
    top: 55%;
    left: 50%;
    display: flex;
    gap: 0;
    transform: translateX(-50%);
    align-self: center;
    align-items: center;
    z-index: 100;
  }
`;

export default TableInfo;
