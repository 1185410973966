import React from 'react';
import Text from '../../common/text/Text';
import styled from '@emotion/styled';
import { useBalance } from '../../../store/api/responseTypes';
import { toFixedFloor } from '../../../utils/StringUtil';

interface CurrencyViewProps {
  balances: useBalance[];
}

const CurrencyView = ({ balances }: CurrencyViewProps) => {
  return (
    <StyledCurrencies>
      <StyledCurrenciesItemList>
        {balances?.map((item, index) => (
          <StyledCurrencyItem key={index}>
            <img src={item.symbolImage} alt={item.assetName} />
            <StyledCurrencyContent>
              <StyledCurrencyText>
                <Text text={item.assetName} fontStyle={'TextMd_Semibold'} textAlign={'end'} textColor={'white'} />
                <Text text={item.assetDisplayName} fontStyle={'TextSmall_Medium'} textAlign={'end'} textColor={'gray400'} />
              </StyledCurrencyText>
              <StyledCurrencyText>
                <Text text={toFixedFloor(Number(item?.amount ?? 0), 2)} fontStyle={'TextMd_Semibold'} textColor={'white'} />
                <Text text={`$${toFixedFloor(Number(item?.currencyAmount ?? 0), 2)}`} fontStyle={'TextSmall_Medium'} textColor={'gray400'} />
              </StyledCurrencyText>
            </StyledCurrencyContent>
          </StyledCurrencyItem>
        ))}
      </StyledCurrenciesItemList>
    </StyledCurrencies>
  );
};

const StyledCurrenciesItemList = styled.div``;
const StyledCurrencies = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  background-color: ${({ theme }) => theme.colors.gray750};
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;

  img {
    width: 32px;
    height: 32px;
  }

  @media (max-width: 768px) {
    padding: 12px 16px;
  }
`;
const StyledCurrencyItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const StyledCurrencyContent = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const StyledCurrencyText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export default CurrencyView;
