export enum Colors {
  black = '#000000',
  white = '#ffffff',
  dark = '#191a20',
  primary = '#3f4150',
  secondary = '#8c8d96',

  // Primary Colors
  primary25 = '#F6FEF9',
  primary50 = '#ECFDF3',
  primary100 = '#D1FADF',
  primary200 = '#A6F4C5',
  primary300 = '#6CE9A6',
  primary400 = '#32D583',
  primary500 = '#12B76A',
  primary600 = '#018F69',
  primary700 = '#007052',
  primary800 = '#05603A',
  primary900 = '#054F31',

  // Success same as Primary
  success25 = '#F6FEF9',
  success50 = '#ECFDF3',
  success100 = '#D1FADF',
  success200 = '#A6F4C5',
  success300 = '#6CE9A6',
  success400 = '#32D583',
  success500 = '#12B76A',
  success600 = '#019F69',
  success700 = '#007052',
  success800 = '#05603A',
  success900 = '#054F31',

  // Error Colors
  error25 = '#FFF7F8',
  error50 = '#FEF3F2',
  error100 = '#FEE4E2',
  error200 = '#FECACA',
  error300 = '#FDA29B',
  error400 = '#F97066',
  error500 = '#F04438',
  error600 = '#D92D20',
  error700 = '#B42318',
  error800 = '#912018',
  error900 = '#7A271A',

  // Warning Colors
  warning25 = '#FFFFCF5',
  warning50 = '#FFFAEB',
  warning100 = '#FEF0C7',
  warning200 = '#FEDF89',
  warning300 = '#FEC84B',
  warning400 = '#FDB022',
  warning500 = '#F79009',
  warning600 = '#D68C03',
  warning700 = '#B54708',
  warning800 = '#93370D',
  warning900 = '#7A2E0E',

  // Gray
  gray25 = '#FCFCFD',
  gray50 = '#F9FAFB',
  gray100 = '#F2F4F7',
  gray200 = '#EAECF0',
  gray300 = '#D0D5DD',
  gray400 = '#98A2B3',
  gray500 = '#667085',
  gray600 = '#475467',
  gray700 = '#344054',
  gray750 = '#2D3748',
  gray800 = '#1D2939',

  gray900 = '#101828',

  // Blue
  blue25 = '#F5FAFF',
  blue50 = '#EFF8FF',
  blue100 = '#D1E9FF',
  blue200 = '#B2DDFF',
  blue300 = '#84CAFF',
  blue400 = '#53B1FD',
  blue500 = '#2E90FA',
  blue600 = '#1570EF',
  blue700 = '#175CD3',
  blue800 = '#1849A9',
  blue900 = '#194185',

  // Blue Light
  blueLight25 = '#F5FBFF',
  blueLight50 = '#F0F9FF',
  blueLight100 = '#E0F2FE',
  blueLight200 = '#B9E6FE',
  blueLight300 = '#7CD4FD',
  blueLight400 = '#36BFFA',
  blueLight500 = '#0BA5EC',
  blueLight600 = '#0086C9',
  blueLight700 = '#026AA2',
  blueLight800 = '#065986',
  blueLight900 = '#0B4A6F',

  // Purple
  purple25 = '#FAFAFF',
  purple50 = '#F4F3FF',
  purple100 = '#EBE9FE',
  purple200 = '#D9D6FE',
  purple300 = '#BDB4FE',
  purple400 = '#9B8AFB',
  purple500 = '#7A5AF8',
  purple600 = '#6938EF',
  purple700 = '#5925DC',
  purple800 = '#4A1FB8',
  purple900 = '#3E1C96',

  // Orange
  orange25 = '#FFFAF5',
  orange50 = '#FFF6ED',
  orange100 = '#FFEAD5',
  orange200 = '#FDDCAB',
  orange300 = '#FEB273',
  orange400 = '#FD853A',
  orange500 = '#FB6514',
  orange600 = '#EC4A0A',
  orange700 = '#C4320A',
  orange800 = '#9C2A10',
  orange900 = '#7E2410',

  white_20 = '#00000033',
  white_30 = '#0000004D',
  white_05 = '#FFFFFF0D',
  gray600_50 = '#47546780',
  gray600_30 = '#4754674D',
  gray600_20 = '#47546733',
  gray600_70 = '#475467B2',
  gray800_90 = '#1D2939E6',
  gray800_80 = '#1D2939CC',
  white_40 = '#FFFFFF66',

  'red-gradient' = 'linear-gradient(180deg, #df4d4a 0%, #a33a38 100%)',

  // card gradient
  'gradient-card_blue' = 'linear-gradient(180deg, #175CD3 0%, #0D1D39 100%)',
  'gradient-card_red' = 'linear-gradient(180deg, #F04438 0%, #7B271B 100%)',
  'gradient-card_green' = 'linear-gradient(180deg, #018F69 0%, #0B3524 100%)',
  'gradient-card_gray' = 'linear-gradient(180deg, #344054 0%, #080C15 100%)'
}
