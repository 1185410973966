import React, { useState } from 'react';
import styled from '@emotion/styled';
import { PasswordIcon } from '../../common/icon';
import { LobbyTableList } from '../../../store/api/responseTypes';
import { ReactComponent as EmptyIcon } from 'src/assets/img/lobby-empty.svg';
import Text from '../../common/text/Text';
import { useDispatch } from 'react-redux';
import { ModalType, showModal, showModalWithRequest } from '../../../store/slices/modalSlice';
import useDevice from '../../../hooks/useDevice';
import BaseButton from '../../common/button/BaseButton';
import { useAuthContext } from '../../../hooks/AuthContext';
import { useCreateConfig } from '../../../hooks/useCreateConfig';

interface TableListTemplateProps {
  tableList?: LobbyTableList;
  selectedTable: number;
  setSelectedTable: (tableId: number) => void;
}

const TableListTemplate = ({ tableList, selectedTable, setSelectedTable }: TableListTemplateProps) => {
  const { isAuthorized } = useAuthContext();
  const [isScrolling, setIsScrolling] = useState(false);
  const { isMobile, isTablet } = useDevice();
  const { getAssetInfo } = useCreateConfig();
  const onScrollHandler = (event: any) => {
    const scrollTop = event.target.scrollTop;
    setIsScrolling(scrollTop !== 0);
  };

  const dispatch = useDispatch();

  const showAuthModal = () => {
    dispatch(showModal(ModalType.AuthModal));
  };

  const showCreateTableModal = () => {
    dispatch(showModal(ModalType.CreateTableModal));
  };

  const onCreateTableClick = () => {
    isAuthorized ? showCreateTableModal() : showAuthModal();
  };

  const openTable = (shareCode: string) => {
    if (isMobile || isTablet) return;
    const width = 1600;
    const height = 900;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;
    const url = `/holdem/${shareCode}`;
    const windowName = `${shareCode}`;
    const windowFeatures = `width=${width},height=${height},top=${top},left=${left}`;
    window.open(url, windowName, windowFeatures);
  };
  const handleItemClick = (tableId: number) => {
    if (isMobile || isTablet) {
      dispatch(showModalWithRequest({ type: ModalType.TablePreviewModal, data: { tableId } }));
      return;
    }
    setSelectedTable(tableId);
  };
  const isEmpty = tableList?.my.length === 0 && tableList?.joined.length === 0 && tableList?.general.length === 0;
  return (
    <StyledWrap>
      <StyledHead isScrolling={isScrolling}>
        <StyledHeadWrap>
          <div />
          <div>Table Name</div>
          <div>Stakes</div>
          <div>Buy-in</div>
          <div>Players</div>
        </StyledHeadWrap>
      </StyledHead>
      <StyledListWrap onScroll={onScrollHandler}>
        {!isEmpty ? (
          <StyledTableList>
            {tableList?.my.map(tableInfo => (
              <StyledTableItem onDoubleClick={() => openTable(tableInfo.shareCode)} onClick={() => handleItemClick(tableInfo.tableId)} isSelected={selectedTable === tableInfo.tableId} key={tableInfo.tableId}>
                <StyledItemWrap isMyTable={true} isSelected={selectedTable === tableInfo.tableId}>
                  <div>{tableInfo.hasPassword && <PasswordIcon w={isMobile ? 16 : 20} h={isMobile ? 16 : 20} color={selectedTable === tableInfo.tableId ? 'white' : 'gray300'} tooltip={'Password required'} />}</div>
                  <div>{tableInfo?.tableTitle}</div>
                  <div>
                    {getAssetInfo(tableInfo.asset)?.assetType === 'CRYPTO' && <StyledSymbol src={getAssetInfo(tableInfo.asset)?.symbolImage} alt={getAssetInfo(tableInfo.asset)?.asset} />}
                    {`${getAssetInfo(tableInfo.asset)?.assetType === 'CRYPTO' ? '' : 'P'}${tableInfo?.smallBlindAmount.toLocaleString()} / ${getAssetInfo(tableInfo.asset)?.assetType === 'CRYPTO' ? '' : 'P'}${tableInfo?.bigBlindAmount.toLocaleString()}`}
                  </div>
                  <div>
                    {getAssetInfo(tableInfo.asset)?.assetType === 'CRYPTO' && <StyledSymbol src={getAssetInfo(tableInfo.asset)?.symbolImage} alt={getAssetInfo(tableInfo.asset)?.asset} />}
                    {`${getAssetInfo(tableInfo.asset)?.assetType === 'CRYPTO' ? '' : 'P'}${tableInfo?.minBuyInAmount.toLocaleString()}`}
                  </div>
                  <div>
                    {!isMobile ? (
                      [...Array(tableInfo?.maxSeatCnt)].map((_, i) => <StyledSeatCount isJoined={tableInfo.isSeated && i === 0} isActive={i < tableInfo.activeSeatCnt} key={i} />)
                    ) : (
                      <StyledCountText isSeated={tableInfo.isSeated} isFull={tableInfo.maxSeatCnt === tableInfo.activeSeatCnt} isEmpty={tableInfo.activeSeatCnt === 0}>{`${tableInfo.activeSeatCnt} / ${tableInfo.maxSeatCnt}`}</StyledCountText>
                    )}
                  </div>
                </StyledItemWrap>
              </StyledTableItem>
            ))}
            {tableList?.joined.map(tableInfo => (
              <StyledTableItem onDoubleClick={() => openTable(tableInfo.shareCode)} onClick={() => handleItemClick(tableInfo.tableId)} isSelected={selectedTable === tableInfo.tableId} key={tableInfo.tableId}>
                <StyledItemWrap isMyTable={true} isSelected={selectedTable === tableInfo.tableId}>
                  <div>{tableInfo.hasPassword && <PasswordIcon w={isMobile ? 16 : 20} h={isMobile ? 16 : 20} color={selectedTable === tableInfo.tableId ? 'white' : 'gray300'} tooltip={'Password required'} />}</div>
                  <div>{tableInfo?.tableTitle}</div>
                  <div>
                    {getAssetInfo(tableInfo.asset)?.assetType === 'CRYPTO' && <StyledSymbol src={getAssetInfo(tableInfo.asset)?.symbolImage} alt={getAssetInfo(tableInfo.asset)?.asset} />}
                    {`${getAssetInfo(tableInfo.asset)?.assetType === 'CRYPTO' ? '' : 'P'}${tableInfo?.smallBlindAmount.toLocaleString()} / ${getAssetInfo(tableInfo.asset)?.assetType === 'CRYPTO' ? '' : 'P'}${tableInfo?.bigBlindAmount.toLocaleString()}`}
                  </div>
                  <div>
                    {getAssetInfo(tableInfo.asset)?.assetType === 'CRYPTO' && <StyledSymbol src={getAssetInfo(tableInfo.asset)?.symbolImage} alt={getAssetInfo(tableInfo.asset)?.asset} />}
                    {`${getAssetInfo(tableInfo.asset)?.assetType === 'CRYPTO' ? '' : 'P'}${tableInfo?.minBuyInAmount.toLocaleString()}`}
                  </div>
                  <div>
                    {!isMobile ? (
                      [...Array(tableInfo?.maxSeatCnt)].map((_, i) => <StyledSeatCount isJoined={tableInfo.isSeated && i === 0} isActive={i < tableInfo.activeSeatCnt} key={i} />)
                    ) : (
                      <StyledCountText isSeated={tableInfo.isSeated} isFull={tableInfo.maxSeatCnt === tableInfo.activeSeatCnt} isEmpty={tableInfo.activeSeatCnt === 0}>{`${tableInfo.activeSeatCnt} / ${tableInfo.maxSeatCnt}`}</StyledCountText>
                    )}
                  </div>
                </StyledItemWrap>
              </StyledTableItem>
            ))}
            {tableList?.general.map(tableInfo => (
              <StyledTableItem onDoubleClick={() => openTable(tableInfo.shareCode)} onClick={() => handleItemClick(tableInfo.tableId)} isSelected={selectedTable === tableInfo.tableId} key={tableInfo.tableId}>
                <StyledItemWrap isSelected={selectedTable === tableInfo.tableId}>
                  <div>{tableInfo.hasPassword && <PasswordIcon w={isMobile ? 16 : 20} h={isMobile ? 16 : 20} color={selectedTable === tableInfo.tableId ? 'white' : 'gray300'} tooltip={'Password required'} />}</div>
                  <div>{tableInfo?.tableTitle}</div>
                  <div>
                    {getAssetInfo(tableInfo.asset)?.assetType === 'CRYPTO' && <StyledSymbol src={getAssetInfo(tableInfo.asset)?.symbolImage} alt={getAssetInfo(tableInfo.asset)?.asset} />}
                    {`${getAssetInfo(tableInfo.asset)?.assetType === 'CRYPTO' ? '' : 'P'}${tableInfo?.smallBlindAmount.toLocaleString()} / ${getAssetInfo(tableInfo.asset)?.assetType === 'CRYPTO' ? '' : 'P'}${tableInfo?.bigBlindAmount.toLocaleString()}`}
                  </div>
                  <div>
                    {getAssetInfo(tableInfo.asset)?.assetType === 'CRYPTO' && <StyledSymbol src={getAssetInfo(tableInfo.asset)?.symbolImage} alt={getAssetInfo(tableInfo.asset)?.asset} />}
                    {`${getAssetInfo(tableInfo.asset)?.assetType === 'CRYPTO' ? '' : 'P'}${tableInfo?.minBuyInAmount.toLocaleString()}`}
                  </div>
                  <div>
                    {!isMobile ? (
                      [...Array(tableInfo?.maxSeatCnt)].map((_, i) => <StyledSeatCount isJoined={tableInfo.isSeated && i === 0} isActive={i < tableInfo.activeSeatCnt} key={i} />)
                    ) : (
                      <StyledCountText isSeated={tableInfo.isSeated} isFull={tableInfo.maxSeatCnt === tableInfo.activeSeatCnt} isEmpty={tableInfo.activeSeatCnt === 0}>{`${tableInfo.activeSeatCnt} / ${tableInfo.maxSeatCnt}`}</StyledCountText>
                    )}
                  </div>
                </StyledItemWrap>
              </StyledTableItem>
            ))}
          </StyledTableList>
        ) : (
          <StyledEmpty>
            <EmptyIcon />
            <Text fontStyle={'TextSmall_Medium'} textColor={'white'} text={'Be a Hold’em game host'} marginBottom={10} />
            <BaseButton textStyle="TextSmall_Semibold" size="btn40" color="primary" textColor="white" onClick={onCreateTableClick}>
              Create Table
            </BaseButton>
          </StyledEmpty>
        )}
      </StyledListWrap>
    </StyledWrap>
  );
};

const StyledSymbol = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const StyledCountText = styled.div<{ isEmpty?: boolean; isFull?: boolean; isSeated?: boolean }>`
  ${({ theme }) => theme.textStyles.TextXs_Medium}
  color: ${({ theme, isEmpty, isFull, isSeated }) => (isEmpty ? theme.colors.gray400 : isFull ? theme.colors.error500 : isSeated ? theme.colors.warning500 : theme.colors.success500)};
`;

const StyledItemWrap = styled.div<{ isSelected?: boolean; isMyTable?: boolean }>`
  padding: 8px 16px;
  display: flex;
  gap: 16px !important;
  border-radius: 4px;

  ${({ theme, isSelected, isMyTable }) => isMyTable && !isSelected && `background-color: ${theme.colors.gray900};`}
  &:hover {
    @media (min-width: 768px) {
      background-color: ${({ theme, isSelected }) => !isSelected && theme.colors.gray750} !important;
    }
  }

  transition: background-color 0.2s;

  @media (max-width: 768px) {
    gap: 12px !important;
    padding: 8px;
  }
`;

const StyledEmpty = styled.div`
  width: 164px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  @media (max-width: 768px) {
    min-height: 100%;
  }
`;

const StyledListWrap = styled.div`
  position: relative;
  overflow: scroll;
  height: 100%;
  padding-top: 48px;
  padding-bottom: 16px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding-top: 34px;
    padding-bottom: 8px;
    overflow: inherit;
    height: max-content;
    min-height: 482px;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledSeatCount = styled.div<{ isActive?: boolean; isJoined?: boolean }>`
  display: flex;
  flex: none !important;
  width: 14px !important;
  height: 14px;
  border-radius: 2px;
  background-color: ${({ theme, isJoined, isActive }) => (isJoined ? theme.colors.warning400 : isActive ? theme.colors.primary500 : theme.colors.gray500)};
`;

const StyledBaseItem = styled.div<{ isSelected?: boolean }>`
  display: flex;
  gap: 16px;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
  }

  div:nth-of-type(1) {
    width: 20px;
  }

  div:nth-of-type(2) {
    flex: 4.7;
  }

  div:nth-of-type(3) {
    flex: 2.3;
  }

  div:nth-of-type(4) {
    flex: 1.5;
  }

  div:last-of-type {
    flex: 2.5 !important;
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    gap: 12px;
    div:nth-of-type(1) {
      width: 16px;
    }

    div:nth-of-type(2) {
      flex: 3;
    }

    div:nth-of-type(3) {
      flex: 2.3;
    }

    div:nth-of-type(4) {
      flex: 1.4;
    }

    div:last-of-type {
      flex: 1 !important;
    }
  }
`;

const StyledTableItem = styled(StyledBaseItem)`
  padding: 0 16px;
  ${({ theme }) => theme.textStyles.TextMd_Medium}
  color: ${({ theme, isSelected }) => (isSelected ? theme.colors.white : theme.colors.gray300)};

  ${({ isSelected }) => isSelected && 'background-color: #68DDC126;'}
  div:nth-of-type(2) {
    color: ${({ theme, isSelected }) => (isSelected ? theme.colors.white : theme.colors.warning200)};
    display: block;
    //text-overflow: ellipsis;
    //overflow: hidden;
    //white-space: nowrap;
  }

  div:nth-of-type(4) {
    color: ${({ theme }) => theme.colors.white};
  }

  div:last-of-type {
    gap: 1px;
  }

  @media (max-width: 768px) {
    padding: 0 8px;
    overflow: inherit;
    ${({ theme }) => theme.textStyles.TextXm_Medium}
  }
`;

const StyledHeadWrap = styled(StyledBaseItem)`
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: 100%;
  @media (max-width: 768px) {
    padding: 0 8px !important;
  }
`;

const StyledHead = styled.div<{ isScrolling?: boolean }>`
  display: flex;
  z-index: 5;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0 16px;
  ${({ theme }) => theme.textStyles.TextMd_Medium}
  height: 48px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray750};
  justify-content: flex-start;
  // box-shadow: ${({ isScrolling }) => isScrolling && '0px 2px 4px 0px rgba(0, 0, 0, 0.15)'};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

  div {
    color: ${({ theme }) => theme.colors.gray400};
  }

  div:last-of-type {
    flex: 1;
    width: 100%;
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    padding: 0 8px;
    height: 34px;
    ${({ theme }) => theme.textStyles.TextXm_Medium}
  }
`;

const StyledWrap = styled.div`
  position: relative;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  min-height: 618px;
  max-height: 55vh;
  height: 55vh;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray800};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  @media (max-width: 768px) {
    border-radius: 0;
    max-height: max-content;
    height: max-content;
    min-height: 482px;
    margin-bottom: 64px;
  }
`;

const StyledTableList = styled.div`
  overflow: scroll;
  display: flex;
  box-sizing: border-box;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  width: 100%;
  padding-top: 16px;
  gap: 8px;
  @media (max-width: 768px) {
    padding-top: 14px;
    gap: 8px;
    height: max-content;
  }
`;

export default TableListTemplate;
