import React, { createContext, useContext, ReactNode } from 'react';
import useTable from './useTable';

import { AddChipRequests, Ask, Card, EventHeader, GameRound, GameType, HandStrength, HeaderAction, HeaderType, Player, Roles, Seat, SidePot, TableAssetInfo, TableSetting, TableSnapshot, TableUpdate, TableUser } from 'src/store/slices/streamingTypes';
import { SeatStatus } from '../store/api/responseTypes';

interface TableContextValue {
  tableId: number;
  name?: string;
  tableSnapshot: TableSnapshot;
  header?: EventHeader;
  winnerType?: 'villain' | 'hero' | undefined;
  handId?: number;
  shareCode?: string;
  isLastHandAction?: boolean;
  tableSettings?: TableSetting;
  action?: HeaderAction;
  headerType?: HeaderType;
  updateInfo?: TableUpdate;
  players?: Player[];
  seats?: Seat[];
  handRound?: GameRound;
  mySeatData?: Seat;
  needsChipGathering?: boolean;
  gameType?: GameType;
  mySeatId?: number;
  mySeatStatus?: SeatStatus;
  myPlayerData?: Player;
  myUserData?: any;
  isMyTurn?: boolean;
  communityCards?: Card[];
  pot?: number;
  sidePots?: SidePot[];
  totalPot?: number;
  totalPotForDisplay: string;
  amountToCall?: number;
  handStrength?: HandStrength;
  isOwner?: boolean;
  tableCallAmount?: number;
  tableStatus?: string;
  bbAmount?: number;
  sbAmount?: number;
  winners?: any[];
  myAllowedActions?: any[];
  minRaiseAmount?: number | null;
  reservedSitOut?: boolean;
  tableUsers?: TableUser[];
  myCombination?: string;
  isWaiting?: boolean;
  isChatEnable?: boolean;
  availableSeats: number;
  canReserve: boolean;
  maxAllInAmount?: number;
  betUnit: number;
  adjustBetToBetUnit: (betAmount: number) => number;
  adjustBetToBetUnitForRaise: (betAmount: number) => number;
  myStack: number;
  chipRequests: AddChipRequests[];
  isAddChipAvailable: boolean;
  myTableAction?: any;
  myUserTotalStack?: number;
  tableAssetInfo?: TableAssetInfo;
  chipDecimal?: number;
  roles?: Roles;
  isTieGame?: boolean;
  ask?: Ask;
  canStart: boolean;
  canSeatBackIn: boolean;
  canBuyIn: boolean;
  prepareStart: boolean;
}

const TableContext = createContext<TableContextValue | undefined>(undefined);

export const TableProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const table = useTable();
  return <TableContext.Provider value={table}>{children}</TableContext.Provider>;
};

export const useTableContext = (): TableContextValue => {
  const context = useContext(TableContext);
  if (!context) {
    throw new Error('useTableContext must be used within a TableProvider');
  }
  return context;
};
