import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onValueChange?: (value: string) => void; // 콜백 함수 타입 추가
  suffix?: 'Max' | 'Min';
  onSuffixClick?: () => void;
}

const TextNumberInput: React.FC<InputProps> = ({ onValueChange, min, max, suffix, onSuffixClick, ...props }) => {
  const [inputValue, setInputValue] = useState('');
  const [inputWidth, setInputWidth] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    onValueChange?.(inputValue); // 입력값이 변경될 때마다 콜백 호출
  }, [inputValue, onValueChange]);

  const handleChange = (v: string) => {
    let text = v;
    let result = text;

    // 값이 숫자와 소수점으로만 구성되어 있는지 확인하고, 소수점이 두 개 이상이 아닌지 확인
    if (/[^0-9.]+/.test(result)) {
      result = result.replace(/[^0-9.]+/g, '');
    }

    // 소수점이 두 개 이상 입력되는 것을 방지
    if (result.split('.').length > 2) {
      result = result.split('.').slice(0, 2).join('.');
    }

    if (/^[0-9.]*$/.test(result)) {
      // 빈값과 0을 두 번 입력하는 경우에는 0으로 처리합니다.
      if (result === '00' || result === '') result = '0';
      // 숫자와 소수점이 포함된 2자리 이상일 때 조건 체크
      else if (/^[0-9.]{2,}$/.test(result)) {
        // 0과 소수점으로만 구성되어 있으면 0.000 작성 중으로 판단하여 아래 작업을 진행하지 않습니다.
        if (!/^[0.]*$/.test(result)) {
          // 0으로 시작하고 두 번째가 소수점이 아닐 때 (앞에 0이 무시되면서 두 번째 글자가 들어갑니다.)
          if (result.charAt(0) === '0' && result.charAt(1) !== '.') result = result.slice(1);
        }
        // 소수점으로 진행될 때
        if (/\./.test(result)) {
          // 소수점 뒤에 두 자리가 넘어가면 입력을 제외시킵니다.
          if (result.split('.')[1].length > 2) result = result.slice(0, result.length - result.split('.')[1].length + 2);
        }
      }

      if (!/^[0.]*$/.test(result)) {
        // {{min}} 금액 미만일 때 최소 금액으로 고정합니다.
        // if (Number(min) > Number(result)) result = min.toString();

        // {{max}} 금액 초과일 때 최대 금액으로 고정합니다.
        if (max && Number(max) < Number(result)) result = max.toString();
      }

      if (!/^[0.]*$/.test(result)) {
        // 0으로 시작하고 두 번째가 소수점이 아닐 때 (앞에 0이 무시되면서 두 번째 글자가 들어갑니다.)
        if (result.charAt(0) === '0' && result.charAt(1) !== '.') result = result.slice(1);
      }
      // 적용
      text = result;
    }
    setInputValue(text);
  };

  const handleSuffixClick = () => {
    switch (suffix) {
      case 'Min':
        handleChange(String(min ?? ''));
        break;
      case 'Max':
        handleChange(String(max ?? ''));
        break;
    }
  };

  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement !== null) {
      const context = document.createElement('canvas').getContext('2d');
      const font = window.getComputedStyle(inputElement).font;
      if (!context) return;
      context.font = font;
      const textWidth = context.measureText(inputValue.length === 0 ? '0.00' : inputValue).width;
      setInputWidth(textWidth + 2);
    }
  }, [inputValue]);

  const handleWrapperClick = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    if (props.value) {
      handleChange(String(props.value ?? ''));
    }
  }, [props.value]);

  return (
    <Wrap>
      <InputWrapper onClick={handleWrapperClick}>
        <Input {...props} ref={inputRef} type="text" value={inputValue} onChange={e => handleChange(e.target.value)} textWidth={inputWidth} />
        <Suffix isEmpty={inputValue.length === 0}>USDT</Suffix>
      </InputWrapper>
      {suffix && <SuffixWrap onClick={handleSuffixClick}>{suffix}</SuffixWrap>}
    </Wrap>
  );
};
const Wrap = styled.div`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  cursor: pointer;

  &:hover,
  &:focus-within {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.gray600};
    outline: none;
  }
`;
const SuffixWrap = styled.div`
  display: flex;
  height: 100%;
  padding: 16px 24px;
  margin-left: auto;
  ${({ theme }) => theme.ButtonColors.gray};
  color: ${({ theme }) => theme.colors.gray300};
  ${({ theme }) => theme.textStyles.TextSmall_Semibold};
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  user-select: none;
  @media (max-width: 768px) {
    padding: 10px 24px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.gray900};
  border: none;

  padding: 0 16px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray400};
  }
`;

const Input = styled.input<InputProps & { textWidth: number }>`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  color: ${({ theme }) => theme.colors.white};
  background: transparent;
  width: ${props => `${Math.max(0, props.textWidth)}px`}; // 너비를 입력 길이에 따라 동적으로 조정
  border: none;
  outline: none;
  padding: 0;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.gray400};
  }
`;

const Suffix = styled.span<{ isEmpty: boolean }>`
  ${({ theme }) => theme.textStyles.TextMd_Medium};
  align-items: center;
  color: ${({ isEmpty, theme }) => (isEmpty ? theme.colors.gray400 : theme.colors.white)};
  pointer-events: none;
  user-select: none;
  margin-left: 4px;
  @media (max-width: 768px) {
    ${({ theme }) => theme.textStyles.TextSmall_Medium};
  }
`;

export default TextNumberInput;
