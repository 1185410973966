import React from 'react';
import { useLocation } from 'react-router-dom';
import TableHeader from './TableHeader';
import MainHeader from './MainHeader';

const Index = () => {
  const location = useLocation();
  const path = location.pathname.split('/')[1];
  return path === 'holdem' ? <TableHeader /> : <MainHeader path={path} />;
};

export default Index;