import { useLazyGetBalanceQuery } from '../store/api/assets';
import { useSelector } from 'react-redux';
import { selectUserBalance } from '../store/slices/assetSlice';
import { useMemo } from 'react';

export const useMyBalance = () => {
  const userBalanceFromRedux = useSelector(selectUserBalance);
  const [refetchBalance] = useLazyGetBalanceQuery();
  return useMemo(() => {
    return { myBalance: userBalanceFromRedux, refetchBalance };
  }, [userBalanceFromRedux, refetchBalance]);
};
