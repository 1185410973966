import React, { useMemo, useEffect, useCallback } from 'react';
import { animate, useMotionValue, useTransform } from 'framer-motion';
import { ChipProps, ChipStack, TChipColor } from './types';
import { useUserTableSetting } from '../../../hooks/useUserTableSetting';
import { toFixedFloor } from '../../../utils/StringUtil';
import { divideBigNumbers, modBigNumbers, multiplyBigNumbers, subtractBigNumbers } from '../../../utils/BigNumberUtil';
import { useCreateConfig } from '../../../hooks/useCreateConfig';
import { useTableContext } from '../../../hooks/TableProvider';

const chipValues = [
  { decimal: 5000, color: 'brown' },
  { decimal: 2500, color: 'orange' },
  { decimal: 1000, color: 'yellow' },
  { decimal: 500, color: 'purple' },
  { decimal: 100, color: 'black' },
  { decimal: 25, color: 'green' },
  { decimal: 10, color: 'blue' },
  { decimal: 5, color: 'red' },
  { decimal: 1, color: 'white' }
];

const useChip = ({ stack, maxStack, chipValueMultiplier, blindAmount }: ChipProps) => {
  const { tableAssetInfo } = useTableContext();
  const userTableSetting = useUserTableSetting();
  const { getAssetInfo } = useCreateConfig();
  const tableAssetConfig = getAssetInfo(tableAssetInfo?.name);

  const displayStack = useMemo(() => {
    if (userTableSetting.isBlindView && blindAmount?.big) {
      return divideBigNumbers(stack, blindAmount.big).toNumber();
    }
    return stack;
  }, [userTableSetting.isBlindView, stack, blindAmount]);

  const handleConvert = useCallback(() => {
    const chipValueMultiplierDecimal = chipValueMultiplier ?? 1;
    let effectiveAmount = stack > 0 ? Math.max(divideBigNumbers(stack, chipValueMultiplierDecimal).toNumber(), 1) : 0;
    return chipValues.reduce((result: ChipStack[], { decimal, color }) => {
      const numChips = Math.floor(divideBigNumbers(effectiveAmount, decimal).toNumber());
      if (numChips > 0) {
        result.push({ chipColor: color as TChipColor, numChips });
        effectiveAmount = subtractBigNumbers(effectiveAmount, multiplyBigNumbers(decimal, numChips).toNumber()).toNumber();
      }
      return result;
    }, []);
  }, [stack, chipValueMultiplier]);

  const distributeChips = useCallback(() => {
    const chips = handleConvert();
    const totalChips = chips.length;
    const maxChipsPerStack = Math.ceil(totalChips / maxStack!);
    const minChipsPerStack = Math.floor(totalChips / maxStack!);

    return Array.from({ length: maxStack! }, (_, i) => {
      const chipCount = i + 1 >= maxStack! - (totalChips % maxStack!) + 1 ? maxChipsPerStack : minChipsPerStack;
      return chips.splice(0, chipCount);
    });
  }, [handleConvert, maxStack]);

  const animatedStack = useMotionValue(displayStack);

  const rounded = useTransform(animatedStack, latest => {
    const value = latest || 0;
    if (userTableSetting.isBlindView) {
      const hasDecimal = modBigNumbers(value, 1).toNumber() !== 0;
      return toFixedFloor(value, hasDecimal ? 1 : 0).toLocaleString() + ' BB';
    }
    return tableAssetConfig?.assetType === 'BPP' ? value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : value.toLocaleString();
  });

  useEffect(() => {
    if (displayStack !== animatedStack.get()) {
      animate(animatedStack, displayStack, { duration: 0.3 });
    }
  }, [displayStack, animatedStack]);

  return {
    rounded,
    distributeChips
  };
};

export default useChip;
