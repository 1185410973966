import { ChipIcon } from '../icon';
import useChip from './useChip';
import { ChipProps } from './types';
import { Amount, ChipStack, StackWrap } from './style';
import useAnimationControl from '../../../hooks/useAnimation';
import React, { useEffect } from 'react';
import _ from 'lodash';
import styled from '@emotion/styled';
import { HeaderAction } from '../../../store/slices/streamingTypes';
import useDevice from '../../../hooks/useDevice';
import { DELAY_BEFORE_BET, DELAY_GATHER_CHIP, DELAY_ROUND, DELAY_WINNING_CHIP } from '../../../utils/AnimationUtil';
import { motion } from 'framer-motion';
import { useTableContext } from '../../../hooks/TableProvider';

const desktopStackPosition = [
  { top: '-6.3em' },
  { top: '-6.3em', left: '2em' },
  { left: '7.5em', top: '-3em' },
  { left: '7.5em', top: '2.5em' },
  { top: '6.8em' },
  { top: '6.8em' },
  {
    left: '-7.5em',
    top: '2.5em'
  },
  { left: '-7.5em', top: '-3em' },
  { top: '-6.3em', left: '-2.8em' }
];

const mobileStackPosition = [{ top: '-7.4em' }, { top: '-7em' }, { top: '-7em' }, { top: '7.8em' }, { top: '7.8em' }, { top: '7.8em' }, { top: '7.8em' }, { top: '-7em' }, { top: '-7em' }];

const Chip = ({ stack, maxStack = 3, chipGap = 2.5, fontSize = 16, chipValueMultiplier, blindAmount, position, type }: ChipProps) => {
  const { distributeChips, rounded } = useChip({ stack, maxStack, chipValueMultiplier, blindAmount });
  const { isMobile } = useDevice();
  const { reset, betChips, gatherChips, getWinningChips, ref, controls, initial } = useAnimationControl({ fontSize, type: type ?? 'bet' });
  const { isLastHandAction, action } = useTableContext();

  const stackPosition = isMobile ? mobileStackPosition : desktopStackPosition;

  useEffect(() => {
    if (type === 'bet') {
      if (stack > 0) {
        _.delay(async () => {
          await betChips({ hasAnimation: true, chipPosition: position !== undefined ? stackPosition[position] : undefined });
        }, DELAY_BEFORE_BET);
      }
    } else if (type === 'pot') {
      if (stack > 0) {
        _.delay(async () => {
          await betChips({ hasAnimation: false, chipPosition: { left: '0em', top: '0em' } });
        }, DELAY_BEFORE_BET);
      }
    }
  }, [action, stack, type, fontSize]);

  useEffect(() => {
    if (type === 'bet' && isLastHandAction) {
      _.delay(async () => {
        await gatherChips();
        _.delay(() => {
          reset();
        }, DELAY_ROUND);
      }, DELAY_GATHER_CHIP);
    }
  }, [isLastHandAction, type, position]);

  useEffect(() => {
    if (type === 'pot') return;
    if (stack > 0) {
      _.delay(async () => {
        await betChips({ hasAnimation: false, chipPosition: position !== undefined ? stackPosition[position] : undefined });
      }, DELAY_BEFORE_BET);
    }
  }, [position]);

  useEffect(() => {
    if (type !== 'win') return;
    if (action === HeaderAction.ROUND_SETTLEMENT) {
      if (stack > 0) {
        _.delay(async () => {
          await getWinningChips({ chipPosition: position !== undefined ? stackPosition[position] : undefined });
        }, DELAY_WINNING_CHIP);
      }
    } else if (action === HeaderAction.TABLE_START) {
      reset();
    }
  }, [type, action, fontSize, stack, position]);

  const StyledChip = styled.div<{ type: 'pot' | 'bet' | 'win' }>`
    position: ${({ type }) => (type !== 'pot' ? 'absolute' : 'none')};
    width: 5em;
    height: 3.13em;
  `;

  const StyledMotionDiv = styled(motion.div)`
    transform-style: preserve-3d;
    position: ${type === 'pot' ? 'none' : 'absolute'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    z-index: 100;
    width: 5em;
    height: 3.13em;
    @media (max-width: 768px) {
      font-size: 8.5px;
    }
  `;

  return (
    <StyledChip ref={ref} type={type ?? 'bet'}>
      <StyledMotionDiv animate={controls} initial={initial}>
        <StackWrap>
          {distributeChips().map(
            (stack, index: number) =>
              stack.length !== 0 && (
                <ChipStack key={index} chipGap={chipGap} maxStack={stack.reduce((acc, cur) => acc + cur.numChips, 0)}>
                  {stack.reverse().map((chip, index: number) =>
                    Array(chip.numChips)
                      .fill(null)
                      .map((_, i) => <ChipIcon key={'chip' + index + '-' + i} w={23} h={24} chipColor={chip.chipColor} />)
                  )}
                </ChipStack>
              )
          )}
        </StackWrap>
        {stack > 0 && <Amount type={type}>{rounded}</Amount>}
      </StyledMotionDiv>
    </StyledChip>
  );
};

export default React.memo(Chip, (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps); // Using lodash to perform a deep comparison
});
